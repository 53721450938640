import _ from 'lodash';
import React, { PureComponent } from 'react';
import classNames from 'classnames';
import Label from '../field_label/field_label';
import './display_field.scss';

export default class DisplayField extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            id: props.id || _.uniqueId()
        };
    }

    render() {
        const { state: { id }, props: { dataType, label, value, fontType, headingLevel, noPadding, cssClass, ...restOfProps } } = this;
        const MainHtmlTag = headingLevel ? `h${headingLevel}` : 'div';
        const className = classNames('display-field__wrapper', cssClass, {
            'no-padding': noPadding
        });

        return (
            <div className={className}>
                <Label {...{ id, text: label, fieldType: dataType }} />
                <MainHtmlTag className={fontSize[fontType.toUpperCase()]} {...restOfProps}>{value}</MainHtmlTag>
            </div>
        );
    }
}

const fontSize = Object.freeze({
    'BODY': 'body-1',
    'BODYBOLD': 'body-2',
    'SUBHEADING': 'subheading-1',
    'SUBHEADINGBOLD': 'subheading-2',
    'TITLE': 'title',
    'HEADLINE': 'headline'
});

DisplayField.defaultProps = {
    dataType: 'text',
    fontType: 'BODY',
    cssClass: ''
};