import React, { PureComponent } from 'react';
import Button from '../button/button';
import classNames from 'classnames';
import Menu from '../menu/menu';
import _ from 'lodash';

export default class SplitButton extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            id: props.id || _.uniqueId()
        };
    }

    render() {
        const { text, cssClass, icon, size, children, data, type, ...restOfProps } = this.props;
        const { id } = this.state;
        const className = classNames('btn-group split-button', cssClass);
        const iconButton = <Button icon={icon} size={size} type={type} />;
        const menu = <Menu id={`${id}Split`} button={iconButton} data={data}>{children}</Menu>;

        return (
            <div className={className}>
                <Button id={id} size={size} text={text} type={type} {...restOfProps} />
                <div className="menu__wrapper">{menu}</div>
            </div>
        );
    }
}

SplitButton.defaultProps = {
    text: '',
    cssClass: '',
    icon: 'caret-down',
    size: 'DEFAULT',
    type: 'DEFAULT'
};
