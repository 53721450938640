import React, { PureComponent } from 'react';
import _ from 'lodash';

export default class ControlledViewRelay extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { values: props && props.values || {} };
        this.updateState = (newValues, path) => this.setState(state => ({ values: _.set({...state.values}, path, newValues) }));
        this.setViewPath = path => this.setState({ path });
    }

    render () {
        const { ViewClass, values, ...restOfProps } = this.props;

        return (
            <ViewClass
                {...restOfProps}
                values={this.state.values[this.state.path]}
                onValueChange={this.updateState}
                onMount={this.setViewPath}
            />
        );
    }
}