import React, { PureComponent } from 'react';
import _ from 'lodash';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(fas, far, fab);

export default class Icon extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { id: props.id || _.uniqueId() };
    }

    render() {
        const { props: { ...restOfProps }, state: { id } } = this;

        return (
            <FontAwesomeIcon id={id} {...restOfProps} />
        );
    }
}

Icon.defaultProps = {
    icon: 'question'
};