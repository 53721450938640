import React, { PureComponent } from 'react';
import { List } from 'react-virtualized';

export default class VirtualizedList extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const { children, selectProps } = this.props;
        return Array.isArray(children) ? (
            <List
                id={`${selectProps.id}-List`}
                height={Math.min(360, children.length * 36 || 36)}
                width={10000}
                autoWidth={true}
                rowCount={children.length}
                rowHeight={36}
                scrollToIndex={selectProps.scrollToRow}
                rowRenderer={({ key, index, style }) => {
                        return <div key={key} style={style}>{children[index]}</div>;
                    }
                }
            />
        )
        : children;
    }
}