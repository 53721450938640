import React, { PureComponent } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import './button.scss';
import Icon from '../icon/icon';

export default class IconButton extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { id: props.id || _.uniqueId() };
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.clickHandler = this.clickHandler.bind(this);
    }

    handleKeyDown(event) {
        switch (event.keyCode) {
            case 32:
            case 13: this.clickHandler(event); break;
            default: return;
        }
        event.preventDefault();
        event.stopPropagation();
    }

    clickHandler(event) {
        const { onClick, triggerNavigation, navigationTo, navigationSection, value } = this.props;

        if (triggerNavigation && navigationSection && navigationTo) {
            triggerNavigation(navigationSection, navigationTo);
        }

        if (onClick) {
            onClick({ ...event, ...{ name: this.props.name } }, value);
        }
    }

    render() {
        const { props: { cssClass, type, name, disabled, href, onClick, onBlur, onFocus, title, icon, customIcon,
            draggable, buttonRef, triggerNavigation, navigationTo, navigationSection, size, ...restOfProps }, state: { id } } = this;
        const className = classNames('icon-btn', types[type.toUpperCase()], cssClass);

        return (
            <a
                ref={buttonRef}
                name={name}
                id={id}
                className={className}
                role='button'
                disabled={disabled}
                title={title}
                aria-label={title}
                onClick={this.clickHandler}
                onKeyDown={this.handleKeyDown}
                onBlur={onBlur}
                onFocus={onFocus}
                {...(href && onClick === null) ? { href } : ""}
                tabIndex="1"
                draggable={draggable}
            >
                {!customIcon ? <Icon icon={icon} {...restOfProps} /> : null}
            </ a>
        );
    }
}

export const types = Object.freeze({
    'DEFAULT': '',
    'BORDER': 'icon-btn--border',
    'DARK': 'icon-btn--dark'
});

IconButton.defaultProps = {
    type: types.DEFAULT,
    disabled: false,
    href: '',
    onClick: null,
    onBlur: null,
    onFocus: null,
    cssClass: '',
    draggable: false
};