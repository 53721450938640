import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import Select from './select';
import './select-editor.scss';

export default class SelectEditor extends PureComponent {
    constructor(props) {
        super(props);

        var selectedValue = {};
        if (props.data !== undefined) {
            selectedValue[props.valueField] = props.data[props.valueId][props.valueField] !== undefined
                ? props.data[props.valueId][props.valueField]
                : props.data[props.valueId];

            selectedValue[props.displayField] = props.value && props.value[props.displayField] !== undefined
                ? props.value[props.displayField]
                : props.value;

            if (props.descriptionField) {
                selectedValue[props.descriptionField] = props.value && props.value[props.descriptionField] !== undefined
                    ? props.value[props.descriptionField]
                    : props.data[props.descriptionId] || '';
            }
        }

        this.state = {
            id: props.id || this.generateId(this.props),
            selectedValue: selectedValue,
            wrapperStyle: { display: 'none' },
            translateY: 0
        };

        this.selectElement = null;
        this.handleChange = this.handleChange.bind(this);
        this.setSelectPosition = this.setSelectPosition.bind(this);
        this.handleKeyboardPressed = this.handleKeyboardPressed.bind(this);
        this.translateSelect = this.translateSelect.bind(this);
    }

    generateId({ eGridCell, rowIndex }){
        return `selectEditorComp-Id${eGridCell.attributes['comp-id'].value}Col-Id${eGridCell.attributes['col-id'].value}Row-id${rowIndex}`;
    }

    handleChange({ target: { value } }) {
        const { displayField, valueField } = this.props;

        if (value === null) {
            value = {};
            value[valueField] = '';
            value[displayField] = '';
        }

        this.setState({ selectedValue: value });
    }

    getValue() {
        return this.state.selectedValue;
    }

    afterGuiAttached() {
        const { cellStartedEdit, api: { gridCore }, node } = this.props;

        const agBodyViewport = gridCore.getGui().getElementsByClassName('ag-body-viewport')[0];
        const agCenterColsViewport = gridCore.getGui().getElementsByClassName('ag-center-cols-viewport')[0];

        this.inputElement = document.getElementById(this.state.id).getElementsByTagName('input')[0];
        this.inputElement.tabIndex = -1;

        this.inputElement.addEventListener('keydown', this.handleKeyboardPressed);

        agBodyViewport.addEventListener('scroll', this.setSelectPosition);
        agCenterColsViewport.addEventListener('scroll', this.setSelectPosition);
        node.eventService.addEventListener("topChanged", this.translateSelect);

        this.setSelectPosition();

        if (cellStartedEdit) {
            this.selectElement.focus();
        }
    }

    componentWillUnmount() {
        const { api, node } = this.props;
        const agBodyViewport = api.gridCore.getGui().getElementsByClassName('ag-body-viewport')[0];
        const agCenterColsViewport = api.gridCore.getGui().getElementsByClassName('ag-center-cols-viewport')[0];

        agBodyViewport.removeEventListener('scroll', this.setSelectPosition);
        agCenterColsViewport.removeEventListener('scroll', this.setSelectPosition);
        this.inputElement.removeEventListener('keydown', this.handleKeyboardPressed);
        node.eventService.removeEventListener("topChanged", this.translateSelect);
    }

    translateSelect({ node }) {
        if (node.rowIndex === this.props.node.rowIndex && !this.state.wrapperStyle.transform) {
            this.setState(prevState => ({ wrapperStyle: { ...prevState.wrapperStyle, transform: `translateY(${prevState.translateY}px)` } }));
        }
    }

    setSelectPosition() {
        const { eGridCell, rowIndex, api, rowHeight, agGridReact } = this.props;
        const offsetLeft = 8;
        const cellRects = eGridCell.getClientRects()[0];
        const agRootRects = agGridReact.eGridDiv.getElementsByClassName('ag-root')[0].getClientRects()[0];
        const agBodyViewport = api.gridCore.getGui().getElementsByClassName('ag-body-viewport')[0];
        const agCenterColsViewport = api.gridCore.getGui().getElementsByClassName('ag-center-cols-viewport')[0];
        const finalTop = (((rowIndex % api.paginationGetPageSize()) + 1) * rowHeight) - agBodyViewport.scrollTop;
        const top = cellRects.top - agRootRects.top;
        const translateY = finalTop - top;

        const width = eGridCell.offsetWidth - offsetLeft;
        const left = eGridCell.offsetLeft - agCenterColsViewport.scrollLeft + offsetLeft;
        const paddingLeft = agCenterColsViewport.scrollLeft - eGridCell.offsetLeft;
        const paddingRight = left + width + offsetLeft - agBodyViewport.offsetWidth;
        const realWidth = width - (paddingLeft > 0 ? paddingLeft : 0) - (paddingRight > 0 ? paddingRight : 0);

        var style = {
            display: (finalTop >= rowHeight && finalTop <= agBodyViewport.clientHeight && realWidth > 80) ? 'block' : 'none',
            position: 'absolute',
            top: `${top}px`,
            width: `${width}px`,
            left: `${left}px`,
            zIndex: 1,
            paddingLeft: paddingLeft > 0 ? `${paddingLeft}px` : '',
            paddingRight: paddingRight > 0 ? `${paddingRight}px` : ''
        };

        this.setState({ wrapperStyle: style, translateY });
    }

    handleKeyboardPressed(event) {
        const { api, rowIndex, column } = this.props;
        if (event.keyCode === 9) {
            event.preventDefault();

            if (this.selectElement.isOpen()) {
                this.setState({
                    selectedValue: this.selectElement.getFocusedOption()
                });
            }

            api.setFocusedCell(rowIndex, column.colId, null);

            if (event.shiftKey) {
                api.tabToPreviousCell();
            }
            else {
                api.tabToNextCell();
            }
        } else if ((event.keyCode === 13 || event.keyCode === 27) && !this.selectElement.isOpen()) {
            const focusedCell = api.getFocusedCell();
            api.stopEditing(event.keyCode === 27);
            api.setFocusedCell(focusedCell.rowIndex, focusedCell.column.colId, null);
        }
    }

    focusIn() {
        this.selectElement.focus();
    }

    render() {
        const { id, selectedValue, wrapperStyle } = this.state;
        const { displayField, valueField, descriptionField, editorData, options, filtering, colDef: { field } } = this.props;

        var selectOptions = options;
        if (editorData[field]) {
            selectOptions = editorData[field];
        }

        return (
            <div>
                {ReactDOM.createPortal(
                    <div className="select-editor" style={wrapperStyle}>
                        <Select
                            ref={ref => { this.selectElement = ref; }}
                            id={id}
                            value={selectedValue}
                            options={selectOptions}
                            displayField={displayField}
                            valueField={valueField}
                            descriptionField={descriptionField}
                            onChange={this.handleChange}
                            filtering={filtering}
                        />
                    </div>,
                    this.props.agGridReact.eGridDiv.getElementsByClassName('ag-root')[0])}
            </div>
        );
    }
}

SelectEditor.defaultProps = {
    options: [],
    displayField: '',
    valueField: '',
    filtering: true,
    valueId: '',
    editorData: {},
    colDef: { field: '' }
};