import _ from 'lodash';
import React, { PureComponent } from 'react';
import classNames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AutoSizer from 'react-virtualized-auto-sizer';
import ListItem from './item';
import Skeleton from './skeleton';
import * as ObjectHelper from '../../util/objectHelper';
import EmptyState from '../emptyState/emptyState';

export default class List extends PureComponent {
    constructor(props) {
        super(props);

        this.state = { activeItem: null };

        this.id = props.id || _.uniqueId();

        this.config = typeof props.config === 'object' ? props.config : JSON.parse(props.config);
        this.getFilterFunction = createGetFilterFunction(this.config);

        this.handleClick = this.handleClick.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.setPerfectScrollbarRef = this.setPerfectScrollbarRef.bind(this);
    }

    setPerfectScrollbarRef(ref) {
        this.perfectScrollbarRef = ref ? ref._container : null;
    }

    componentDidMount() {
        if (this.perfectScrollbarRef) {
            this.fetchMoreWhenNoScroll();
        }
    }

    componentDidUpdate() {
        if (this.perfectScrollbarRef) {
            this.fetchMoreWhenNoScroll();
        }
    }

    handleScroll(scrollableContainer) {
        const { isFetching, onFetchMore, hasFetchedAll, pageSize, data, name } = this.props;
        const { clientHeight, scrollTop, scrollHeight } = scrollableContainer;
        const almostEndOfScrollReached = clientHeight + scrollTop > scrollHeight * 0.85;

        if (onFetchMore && !isFetching && !hasFetchedAll && almostEndOfScrollReached) {
            onFetchMore(pageSize, data.length, { name });
        }
    }

    fetchMoreWhenNoScroll() {
        const { isFetching, onFetchMore, hasFetchedAll, pageSize, data, name } = this.props;

        if (onFetchMore && !isFetching && !hasFetchedAll && this.listHasNoScroll()) {
            onFetchMore(pageSize, data.length, { name });
        }
    }

    listHasNoScroll() {
        const { clientHeight, scrollHeight, scrollTop } = this.perfectScrollbarRef;
        return clientHeight === scrollHeight && scrollTop === 0;
    }

    render() {
        const { loading, emptyState, filterValue } = this.props;
        const actualData = this.getActualData();
        const hasData = actualData && Array.isArray(actualData) && actualData.length !== 0;
        let toRender = '';

        if (loading) {
            toRender = <Skeleton length={this.config.skeletonLength} />;
        } else if (emptyState && !hasData) {
            if (emptyState === true) {
                toRender = <EmptyState />;
            } else if (filterValue) {
                toRender = this.getEmptyState(emptyState.noResult);
            } else {
                toRender = this.getEmptyState(emptyState.noData);
            }
        } else if (hasData) {
            toRender = this.getList(actualData);
        }

        return toRender;
    }

    getEmptyState(emptyState) {
        let resultEmptyState;

        if (!emptyState) {
            resultEmptyState = <EmptyState />;
        } else if (emptyState.hasOwnProperty('title') || emptyState.hasOwnProperty('subtitle') || emptyState.hasOwnProperty('img')) {
            resultEmptyState = <EmptyState
                headerValue={emptyState.title}
                subheaderValue={emptyState.subtitle}
                img={emptyState.img || false}
            />;
        } else {
            resultEmptyState = emptyState;
        }

        return resultEmptyState;
    }

    getActualData() {
        const { data, filterValue } = this.props;
        return (filterValue && data) ? data.filter(this.getFilterFunction(filterValue)) : data;
    }

    getList(data) {
        const { height, onFetchMore } = this.props;

        return !height && onFetchMore ?
            <AutoSizer disableWidth>
                {({ height: autoHeight }) => this.createList(data, autoHeight)}
            </AutoSizer> :
            this.createList(data);
    }

    onEnterPress(event, itemId) {
        if (event.charCode === 13) {
            this.setState({
                activeItem: itemId
            });
        }
    }

    createList(data, autoHeight) {
        const { id, props: { statusBar, cssClass, filterValue, isFetching, left, right, bottom, useEllipsis } } = this;
        const { valueField, primaryTitle, primarySubtitle, secondaryTitle, secondarySubtitle, hasDivider, hasDot, size, selectable } = this.config;

        const height = this.props.height || autoHeight;
        const style = height ? { height } : {};

        return (
            <PerfectScrollbar
                ref={this.setPerfectScrollbarRef}
                style={style}
                onScrollY={this.handleScroll}
            >
                <ul
                    id={id}
                    className={classNames("clist", { "clist--ellipsis": useEllipsis },cssClass)}
                    role="list"
                >
                    {_.map(data, (listItem, index) => {
                        const itemId = valueField ? ObjectHelper.getTemplateValues(valueField, listItem) : `${id}.${index}`;
                        return (
                            <ListItem
                                key={index}
                                id={itemId}
                                hasDivider={hasDivider}
                                hasDot={hasDot}
                                size={size}
                                statusBar={statusBar}
                                selectable={selectable}
                                isActive={this.isActiveItem(itemId)}
                                onKeyPress={(event) => this.onEnterPress(event, itemId)}
                                onClick={this.handleClick}
                                data={listItem}
                                {...{ primaryTitle, primarySubtitle, secondaryTitle, secondarySubtitle }}
                                textToHighlight={filterValue}
                                {...{ left, right, bottom }}
                            />
                        );
                    })}
                    {isFetching && <Skeleton length={2} hasDivider={hasDivider} />}
                </ul>
            </PerfectScrollbar>
        );
    }

    isActiveItem(itemId) {
        const { active } = this.props;

        if (active && !Array.isArray(active)) {
            return itemId === active;
        } else {
            return itemId === this.state.activeItem;
        }
    }

    handleClick(itemId) {
        const { onClick, name, fieldName, triggerQuery, triggerNavigation, navigationTo, navigationSection } = this.props;

        if (triggerNavigation && navigationSection && navigationTo) {
            triggerNavigation(navigationSection, navigationTo);
        }

        this.setState({ activeItem: itemId });

        if (onClick) {
            onClick(itemId, name, fieldName, triggerQuery);
        }
    }
}

List.defaultProps = {
    cssClass: '',
    loading: false,
    config: {},
    pageSize: 50,
    isFetching: false,
    hasFetchedAll: false,
    right: null,
    left: null,
    bottom: null,
    useEllipsis: true
};

function createGetFilterFunction(config) {
    const searchableFieldPaths = _.uniq(
        Object.entries(config)
            .filter(([title]) => title.indexOf('itle') > -1)
            // eslint-disable-next-line no-unused-vars
            .map(([_, fieldPath]) => [...fieldPath.matchAll(/{(.*?)}/g)])
            .map(matchGroup => matchGroup.map(match => match[1])).flat()
    );

    return filterValue => testObject => searchableFieldPaths.some(fieldPath => _.chain(testObject)
        .get(fieldPath).lowerCase().value()
        .indexOf(filterValue.toLowerCase()) > -1);
}