import React, { PureComponent } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import './button.scss';
import Icon from '../icon/icon';

export default class Button extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { id: props.id || _.uniqueId() };

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {
        const { onClick, triggerNavigation, navigationTo, navigationSection, value } = this.props;

        if (triggerNavigation && navigationSection && navigationTo) {
            triggerNavigation(navigationSection, navigationTo);
        }

        if (onClick) {
            onClick(event, value);
        }
    }

    render() {
        const { props: { size, type, text, autoFocus, onClick, triggerNavigation, navigationTo, navigationSection, buttonRef, cssClass, icon, ...restOfProps }, state: { id } } = this;
        const className = classNames('btn', sizes[size.toUpperCase()], types[type.toUpperCase()], cssClass);

        return (
            <button
                {...{ id, ...restOfProps }}
                className={className}
                role='button'
                ref={buttonRef ? buttonRef : comp => comp && autoFocus && comp.focus()}
                onClick={this.handleClick}
            >
                {icon ? <Icon icon={icon} /> : null}{text}
            </button>
        );
    }
}

export const sizes = Object.freeze({
    'SMALL': 'btn--small',
    'LARGE': 'btn--large',
    'DEFAULT': ''
});

export const types = Object.freeze({
    'DEFAULT': '',
    'HAPPY': 'btn--happy',
    'NEGATIVE': 'btn--negative',
    'NEGATIVE-SECONDARY': 'btn--negative-secondary',
    'POSITIVE': 'btn--positive',
    'LINK': 'btn--link',
    'WARNING': 'btn--warning',
    'SYSTEM': 'btn--system'
});

Button.defaultProps = {
    size: sizes.DEFAULT,
    type: types.DEFAULT,
    cssClass: '',
    autoFocus: false
};