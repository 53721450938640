import React, { PureComponent } from 'react';

export default class OutsideAlerter extends PureComponent {
    constructor(props) {
        super(props);

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        const { wrapperRef, props: { handler }} =  this;
        if (handler && !wrapperRef.contains(event.target)) {
            handler(event);
        }
    }

    render() {
        return (
            <div style={{ display: 'table'}} ref={this.setWrapperRef}>
                {this.props.children}
            </div>
        );
    }
}