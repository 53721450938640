import React, {PureComponent} from 'react';
import IconButton from '../../button/icon-button';
import Nullify from '../../nullify';

export default class TopToolbar extends PureComponent {
    constructor(props) {
        super(props);
        this.onButtonClick = ({ name }) => props.handler(name);
    }

    render() {
        const { title, description, fullScreen, nullifyIf, onButtonFocusChange } = this.props;
        const displayDescription = `${title} ${description}`.trim();
        return (
            <Nullify if={nullifyIf}>
                <div className={"document-viewer__top-toolbar"}>
                    <p className={'document-viewer__description'} title={displayDescription}>{displayDescription}</p>
                    <IconButton
                        name="Print"
                        type="dark"
                        icon="print"
                        title="Print"
                        onClick={this.onButtonClick}
                        onFocus={onButtonFocusChange}
                        onBlur={onButtonFocusChange}
                    />
                    <IconButton
                        name="Download"
                        type="dark"
                        icon="download"
                        title="Download"
                        onClick={this.onButtonClick}
                        onFocus={onButtonFocusChange}
                        onBlur={onButtonFocusChange}
                    />
                    <IconButton
                        name="New_tab"
                        type="dark"
                        icon="external-link-alt"
                        title="Open in new tab"
                        onClick={this.onButtonClick}
                        onFocus={onButtonFocusChange}
                        onBlur={onButtonFocusChange}
                    />
                    <IconButton
                        name="Fullscreen"
                        type="dark"
                        icon={fullScreen ? "times" : "expand-arrows-alt"}
                        title={fullScreen ? "Close fullscreen" : "Go fullscreen"}
                        onClick={this.onButtonClick}
                        onFocus={onButtonFocusChange}
                        onBlur={onButtonFocusChange}
                    />
                </div>
            </Nullify>
        );
    }
}

TopToolbar.Events = Object.freeze({ 'PRINT': 'Print', 'DOWNLOAD': 'Download', 'NEW_TAB': 'New_tab', 'FULLSCREEN': 'Fullscreen' });

TopToolbar.defaultProps = {
    handler() {},
    fullScreen: false,
    nullifyIf: false,
    title: '',
    description: ''
};