import React, { PureComponent } from 'react';
import Title from './title';
import * as ObjectHelper from '../../../../util/objectHelper';

export default class ListItemBody extends PureComponent {
    render() {
        const { data, textToHighlight, ...templateConfig } = this.props;
        const titles = Object.values(templateConfig).map(title => ObjectHelper.getTemplateValues(title, data));

        return (
            <React.Fragment>
                <div className={"clist__item-inner-wrapper clist__item-inner-wrapper--left"}>
                    <Title textToHighlight={textToHighlight} >{titles[0]}</Title>
                    <Title textToHighlight={textToHighlight} isSubtitle={true} >{titles[1]}</Title>
                </div>
                {(titles.length > 2) &&
                    <div className="clist__item-inner-wrapper pl-0 ml-auto text-right clist__item-inner-wrapper--right">
                        <Title textToHighlight={textToHighlight} >{titles[2]}</Title>
                        <Title textToHighlight={textToHighlight} isSubtitle={true} >{titles[3]}</Title>
                    </div>
                }
            </React.Fragment>
        );
    }
}

ListItemBody.defaultProps = {
    data: {},
    textToHighlight: '',
    primaryTitle: ''
};