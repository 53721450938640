import React, { PureComponent } from 'react';
import _ from 'lodash';
import classNames from 'classnames';

export default class Hyperlink extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { id: props.id || _.uniqueId() };
    }

    render() {
        const { props: { url, text, target, draggable, cssClass }, state: { id } } = this;
        const className = classNames('link', cssClass);

        return (
            <a
                id={id}
                href={url}
                className={className}
                target={target}
                draggable={draggable}
            >
                {text}
            </a>
        );
    }
}

Hyperlink.defaultProps = {
    target: '_blank',
    url: '#',
    cssClass: '',
    draggable: false
};