import React, { PureComponent } from 'react';
import _ from 'lodash';
import classNames from 'classnames';

import './index.scss';

export default class Letter extends PureComponent {
    constructor(props) {
        super(props);

        this.id = props.id || _.uniqueId();
    }

    render() {
        return (
            <span id={this.id} className={classNames("clist__item-letter text-center", this.props.cssClass)}>
                {this.props.children[0]}
            </span>
        );
    }
}

Letter.defaultProps = {
    cssClass: '',
    children: ''
};