import React, { PureComponent } from "react";
import IconButton from '../../button/icon-button.jsx';
import './actions.scss';

export default class Actions extends PureComponent {

    getValue() {
        return this.props.actions;
    }

    afterGuiAttached() {
        const { eGridCell, cellStartedEdit } = this.props;

        this.iconElement = eGridCell.getElementsByClassName('icon-btn')[0];

        if (cellStartedEdit) {
            this.iconElement.focus();
        }
    }

    focusIn() {
        this.iconElement.focus();
    }

    render() {
        const { actions } = this.props;

        return (
            <div className='actions--cell-renderer__wrapper'>
                <div>
                    {actions.map((button, index) =>
                        <IconButton
                            key={index}
                            name={button.name}
                            title={button.title}
                            icon={button.icon}
                            type={button.type}
                            disabled={button.disabled}
                            cssClass='actions--cell-renderer'
                            onClick={() => button.clickHandler(this.props, index)}
                        />
                    )}
                </div>
            </div>
        );
    }
}

Actions.defaultProps = {
    actions: []
};