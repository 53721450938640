if (!Intl.NumberFormat.prototype.formatToParts) {
    Intl.NumberFormat.prototype.formatToParts = function(data) {
        return [{
            type: 'default',
            value: data
        }];
    };
    console.warn('Currency formatting is not supported. Please update your browser.');
}

export function addGroupSeparator(value, locale, currencyCode){
    if(!value) return '';
    const groupSeparator = getLocalePart('group', locale, currencyCode);
    const groupLength = getLocalePart('integer', locale, currencyCode).length === 2 ? 2 : 3;
    const decimalSeparator = getLocalePart('decimal', locale, currencyCode);
    if (groupSeparator && decimalSeparator) {
        const regexRemoveSeparator = new RegExp(`\\${groupSeparator}`, 'g');
        const regexSeparatorPosition = new RegExp(`(\\d)(?=(\\d{${groupLength}})+(?!\\d))`, 'g');
        let [intPart, decPart] = value.replace(regexRemoveSeparator, '').split(decimalSeparator || undefined);

        if(intPart.length > 3){
            const lastThree = intPart.substring(intPart.length-3);
            intPart = intPart.substring(0,intPart.length-3);
            intPart = `${intPart.replace(regexSeparatorPosition, `$1${groupSeparator}`)}${groupSeparator}${lastThree}`;
        }

        return decPart
            ? `${intPart}${decimalSeparator}${decPart}`
            : decPart !== undefined
                ? `${intPart}${decimalSeparator}`
                : `${intPart}`;
    }
    return '';
}

export function formatToLocale(value, locale, currencyCode, decimals){
    if(!value) return '';
    return Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currencyCode,
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
    }).format(unformat(value, locale, currencyCode));
}

export function formatWithoutSymbol(value, locale, currencyCode, decimals){
    if(!value) return '';
    let formattedValue = '';
    Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currencyCode,
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
    }).formatToParts(unformat(value, locale, currencyCode)).forEach(({type, value}) => {
        switch(type){
            case 'currency':
            case 'literal':
                formattedValue += '';
                break;
            default:
                formattedValue += value;
                break;
        }
    });
    return formattedValue;
}

export function unformat(value, locale, currencyCode){
    if(typeof value !== 'string' || value === ''){return value;}
    const groupSeparator = getLocalePart('group', locale, currencyCode);
    if (groupSeparator) {
        const regexRemoveSeparator = new RegExp(`\\${groupSeparator}`, 'g');
        value = value.replace(/[^\d.,-]/g, '');
        value = value.replace(regexRemoveSeparator,'');
        return parseFloat(value.replace(/,(\d+).*$/, '.$1'));
    }
    return '';
}

export function isValidNumber(value, locale, currencyCode){
    if(!value || Number(value)) return true;
    const groupSeparator = getLocalePart('group', locale, currencyCode);
    if (groupSeparator) {
        const decimalSeparator = getLocalePart('decimal', locale, currencyCode);
        const validNumberReg = new RegExp(`^\\d{1,3}([${groupSeparator}]?\\d{1,3})*([${decimalSeparator}]\\d*)?$`, 'gm');
        return validNumberReg.test(value);
    }
    return '';
}

export function getLocalePart(requestedPart, locale, currencyCode){
    return Intl.NumberFormat(locale, {style: 'currency', currency: currencyCode})
        .formatToParts(1000000.1)
        .find(part => part.type === requestedPart)
            ? Intl.NumberFormat(locale, {style: 'currency', currency: currencyCode})
                .formatToParts(1000000.1)
                .find(part => part.type === requestedPart)
                .value
            :'';
}