export const inputSizes = Object.freeze({
    'DEFAULT': {
        type: ''
    },
    'SMALL': {
        type: 'small',
        minWidth: 124
    },
    'MEDIUM': {
        type: 'medium',
        minWidth: 186
    },
    'LARGE': {
        type: 'large',
        minWidth: 272
    }
});

export function isObject(value) {
    return value && typeof value === 'object' && !Array.isArray(value);
}

export function getTemplateValues(template, obj, showFieldName) {
    if (!template) {
        return '';
    } else if (isSingleTemplate(template)) {
        return getTemplateValue(template.substring(1, template.length - 1), obj, showFieldName);
    } else {
        const regex = /{((\w+\.*)+)}/g;

        return template.replace(regex, (match, group) => getTemplateValue(group, obj, showFieldName));
    }
}

function getTemplateValue(path, obj, showFieldName) {
    const value = getDeepValue(obj, path);

    if (value === undefined || value === null) {
        return showFieldName ? getFieldName(path) : '';
    }

    return value;
}

export function getDeepValue(obj, path) {
    if (!isObject(obj) || Object.keys(obj).length === 0) {
        return undefined;
    }

    const pathArray = path.split('.');
    for (var i = 0; i < pathArray.length; i++) {
        obj = obj && obj[pathArray[i]];
    }

    return obj;
}

export function getDeepObject(value, path) {
    const pathArray = path.split('.');
    for (var levelReverseCounter = pathArray.length - 1; levelReverseCounter > 0; levelReverseCounter--) {
        value = { [pathArray[levelReverseCounter]]: value };
    }
    return { [pathArray[0]]: value };
}

export function isSingleTemplate(template) {
    return template.indexOf('{') === 0 &&
        template.lastIndexOf('{') === 0 &&
        template.indexOf('}') === template.length - 1 &&
        template.lastIndexOf('}') === template.length - 1;
}

function getFieldName(path) {
    const pathArray = path.split('.');
    const fieldName = pathArray[pathArray.length - 1];

    return `${fieldName[0].toUpperCase()}${fieldName.substring(1)}`.replace(/([a-z](?=[A-Z]))/g, '$1 ');
}

export function sortObjectArray(dataArray, sortAttribute){
    return dataArray.sort((first, second) =>
        (first[sortAttribute] > second[sortAttribute])
        ? 1
        : ((second[sortAttribute] > first[sortAttribute])
            ? -1
            : 0));
}