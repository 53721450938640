import React, { PureComponent } from 'react';
import FocusLock from 'react-focus-lock';
import Topbar from '../topbar/topbar';
import FooterContainer from '../footer-container/footer-container';
import SideDrawer from '../side_drawer/side_drawer';
import Container from '../container/container';
import Mask from '../mask/mask';
import PerfectScrollbar from 'react-perfect-scrollbar';
import MediaQuery from 'react-responsive';
import classNames from 'classnames';
import { LiveAnnouncer } from 'react-aria-live';
import './main-container.scss';
import 'react-perfect-scrollbar/dist/css/styles.css';
import TabBar from '../tab-container/tab-bar';

export default class MainContainer extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isSideDrawerCollapsed: props.isSideDrawerCollapsed,
            sideDrawerSize: props.sideDrawerMinSize > 256 ? props.sideDrawerMinSize : 256,
            windowWidth: window.innerWidth,
            activeTab: 0
        };
        this.splitRef = React.createRef();
        this.toggleSideDrawer = this.toggleSideDrawer.bind(this);
        this.setActiveTab = this.setActiveTab.bind(this);
    }

    setActiveTab(activeTab) {
        this.setState({ activeTab });
    }

    generateMainBody() {
        const { header, footer, children, sideDrawer, tabs, showTabsAtTheBottom } = this.props;
        const { isSideDrawerCollapsed, activeTab } = this.state;
        const footerToRender = Array.isArray(footer) ? footer[activeTab] : footer;

        return (
            <div className="main-container__main-section">
                <nav className="main-container__main-section-header">
                    {Array.isArray(header) ? header[activeTab] : header}
                </nav>
                <PerfectScrollbar>
                    <section className="main-container__main-section-content">
                        {tabs ? React.Children.toArray(children)[activeTab] : children}
                    </section>
                </PerfectScrollbar>
                {footerToRender && <FooterContainer>{footerToRender}</FooterContainer>}
                {showTabsAtTheBottom && (
                    <TabBar
                        id={`main-container.bottom-tab-bar`}
                        activeTab={activeTab}
                        tabItems={tabs}
                        onClick={this.setActiveTab}
                        cssClass="tab-bar-bottom tab-wrapper"
                        tabItemCssClass="tabs__tab"
                        activeBarIndicator={false}
                    />
                )}
                {sideDrawer && (
                    <MediaQuery maxWidth={640}>
                        {!isSideDrawerCollapsed && (
                            <Mask style={{ top: '54px', height: 'calc(100vh - 54px)', zIndex: 4001 }} />
                        )}
                    </MediaQuery>
                )}
            </div>
        );
    }

    generateSideDrawerStructure() {
        const { sideDrawer, sideDrawerResizable, sideDrawerMinSize, sideDrawerMaxSize } = this.props;
        const { isSideDrawerCollapsed, sideDrawerSize, windowWidth } = this.state;
        return (
            <Container
                reference={this.splitRef}
                split="vertical"
                showSplitter={true}
                allowResize={isSideDrawerCollapsed ? false : sideDrawerResizable}
                minSize={isSideDrawerCollapsed ? 0 : sideDrawerMinSize}
                maxSize={sideDrawerMaxSize}
                size={isSideDrawerCollapsed || windowWidth <= 640 ? 0 : sideDrawerSize}
                style={{ height: 'inherit' }}
                resizerStyle={{ width: '0px', margin: '0 -6px' }}
                isCollapsed={isSideDrawerCollapsed}
            >
                <FocusLock disabled={windowWidth > 640 || isSideDrawerCollapsed} group="side-drawer-focus-trap">
                    <SideDrawer isCollapsed={isSideDrawerCollapsed}>{sideDrawer}</SideDrawer>
                </FocusLock>
                {this.generateMainBody()}
            </Container>
        );
    }

    toggleSideDrawer() {
        this.setState(prevState => ({
            sideDrawerSize:
                !prevState.isSideDrawerCollapsed && prevState.windowWidth > 640
                    ? this.splitRef.current.state.pane1Size
                    : prevState.sideDrawerSize,
            isSideDrawerCollapsed: !prevState.isSideDrawerCollapsed
        }));
    }

    componentDidMount() {
        window.addEventListener('resize', () => {
            this.setState({ windowWidth: window.innerWidth });
        });
    }

    render() {
        const {
            appTitle,
            sideDrawer,
            showTopbar,
            modals,
            topbar,
            tabs,
            cssClass,
            showTabsAtTheBottom,
            showTopbarLogo,
            topbarLeft
        } = this.props;
        const { windowWidth, isSideDrawerCollapsed, activeTab } = this.state;
        return (
            <main className={classNames('main-container__wrapper', cssClass)}>
                <LiveAnnouncer>
                    {showTopbar && (
                        <FocusLock disabled={windowWidth > 640 || isSideDrawerCollapsed} group="side-drawer-focus-trap">
                            <header>
                                <Topbar
                                    appTitle={appTitle}
                                    leftComponent={topbarLeft}
                                    sideDrawerButton={!!sideDrawer}
                                    onClick={this.toggleSideDrawer}
                                    mobile={windowWidth < 960}
                                    tabs={!showTabsAtTheBottom && tabs}
                                    onTabClick={!showTabsAtTheBottom && this.setActiveTab}
                                    activeTab={activeTab}
                                    showLogo={showTopbarLogo}
                                >
                                    {topbar}
                                </Topbar>
                            </header>
                        </FocusLock>
                    )}
                    <div className="main-container__body">
                        {sideDrawer ? this.generateSideDrawerStructure() : this.generateMainBody()}
                        {modals}
                    </div>
                </LiveAnnouncer>
            </main>
        );
    }
}

MainContainer.defaultProps = {
    appTitle: '',
    isSideDrawerCollapsed: false,
    sideDrawerResizable: false,
    sideDrawerMinSize: 0,
    showTopbar: true,
    cssClass: '',
    tabs: undefined,
    showTabsAtTheBottom: false,
    topbarLeft: null,
    showTopbarLogo: true
};
