import React, { PureComponent } from 'react';
import Relay from './';
import Spinner from '../spinner/spinner';

export default class ModuleRelay extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            ViewClass: null,
            prevGlobalName: null,
            prevModuleScriptURL: null,
            error: null
        };
    }

    static getDerivedStateFromProps({ globalName, moduleScriptURL }, { prevGlobalName, prevModuleScriptURL }) {
        if (prevModuleScriptURL !==  moduleScriptURL || prevGlobalName !== globalName) {
            return {
                prevModuleScriptURL: moduleScriptURL,
                prevGlobalName: globalName,
                ViewClass: null,
                error: null
            };
        }
        return null;
    }
    render () {
        const { state: { ViewClass, error }, props: { appConfig, path, data, onValueChange, name, globalName, moduleScriptURL, LoadingComponent} } = this;
        if (ViewClass) {
            const values = data;
            if (onValueChange) {
                return (<ViewClass {...{ appConfig, values, path, onValueChange, name}} />);
            } else {
                return (<Relay.ControlledView {...{ appConfig, values, name, ViewClass }}/>);
            }
        }
        if (error) return (<Error {...{error, globalName, moduleScriptURL}} />);
        return LoadingComponent ? <LoadingComponent /> : (<Spinner />);
    }

    componentDidMount () {
        loadViewScript(this);
    }

    componentDidUpdate () {
        const { ViewClass, error } = this.state;
        if(!error && !ViewClass) {
            loadViewScript(this);
        }
    }
}

function loadViewScript(instance) {
    const { moduleScriptURL, globalName, supplements = [] } = instance.props;

    const timeout = setTimeout(
        () => instance.setState({ error: `request has timed out (10 seconds) while loading script: ${moduleScriptURL}`}),
        10000
    );
    appendTag( moduleScriptURL, {
        onload() {
            clearTimeout(timeout);
            instance.setState({ ViewClass: window[globalName]});
        },
        onerror(error) {
            clearTimeout(timeout);
            instance.setState({ error, ViewClass: null });
        }
    });

    supplements.forEach(sup => appendTag(sup.path, sup.attributes));
}

function appendTag(src, { type = "text/javascript", ...restOfAttributes }, tagName = "script") {
    const scriptTag = document.createElement(tagName);
    scriptTag.src = src;
    scriptTag.type = type;
    Object.entries(restOfAttributes).forEach(([key, value]) => scriptTag[key] = value);
    document.body.appendChild(scriptTag);
}

function Error({ error, globalName, moduleScriptURL }) {
    return (
        <div>
            <div>{`Error: ${error}, while requesting`}</div>
            <div>{`module: ${moduleScriptURL}`}</div>
            <div>{`for class ${globalName}`}</div>
        </div>
    );
}
