import _ from 'lodash';
import React, { PureComponent } from 'react';
import classNames from 'classnames';
import Container from './../container/container';
import './panel.scss';
import { isObject } from '../../util/objectHelper';
import TabBar from '../tab-container/tab-bar';

export default class Panel extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            id: props.id || _.uniqueId(),
            activeTab: 0
        };

        this.handleTabClick = this.handleTabClick.bind(this);
    }

    handleTabClick(tabNumber) {
        this.setState({ activeTab: tabNumber });
    }

    render() {
        const { header, cssClass, children, footer, flex, isRow, isColumn, isChild, noBodyPadding, noBorder, height, maxWidth, alignSelf, alignItems, alignContent, justifyContent, ...restOfProps } = this.props;
        const { id, activeTab } = this.state;

        const style = {
            maxWidth: maxWidth || '',
            flex: normalizeFlex(flex)
        };

        return (
            <section {...restOfProps}
                id={id}
                className={classNames('panel', 'flex-panel', cssClass, {
                    'panel--child': isChild,
                    'panel--noborder': noBorder,
                    [`align-self-${alignSelf}`]: alignSelf
                })}
                style={style}
            >
                {header &&
                    <Header id={id} config={header} onTabClick={this.handleTabClick} activeTab={activeTab} />
                }
                <Container
                    noPadding={noBodyPadding}
                    height={height}
                    alignItems={alignItems}
                    alignContent={alignContent}
                    justifyContent={justifyContent}
                    cssClass={classNames('panel__content', 'flex-panel-content', {
                        'flex-panel-content--row': isRow,
                        'flex-panel-content--col': isColumn
                    })}
                >
                    {(header && header.tabs) ? React.Children.toArray(children)[activeTab] : children}
                </Container>
                {footer &&
                    <Footer content={footer} />
                }
            </ section>
        );
    }
}

class Header extends PureComponent {
    render() {
        const { id, config, onTabClick, activeTab } = this.props;

        const headerClass = classNames('panel__header', {
            'panel__header--tabs': config.tabs && !config.title && !config.subheader,
            'display-block': config.tabs,
            'panel__header--with--tabs': config.tabs
        });

        const headerTitle = !isObject(config) ? config : config.title;

        return (
            <div className={headerClass}>
                {headerTitle &&
                    <div className='panel__title'>
                        <h2>
                            {headerTitle}
                        </h2>
                    </div>
                }
                {config.subheader &&
                    <div className="panel__subheader">
                        {config.subheader}
                    </div>
                }
                {config.tabs &&
                    <TabBar
                        id={`${id}.tab-bar`}
                        tabItems={config.tabs}
                        onClick={onTabClick}
                        tabItemCssClass="tabs__tab"
                        activeTab={activeTab}
                    />
                }
            </div>
        );
    }
}

class Footer extends PureComponent {
    render() {
        return (
            <div className='panel__footer'>
                {this.props.content}
            </div>
        );
    }
}

const normalizeFlex = (flex) => {
    switch (flex.toString().split(' ').filter(x => x).length) {
        case 1:
            return flex + ' 1 auto';
        case 2:
            return flex + ' auto';
        default:
            return flex;
    }
};


Panel.defaultProps = {
    isColumn: false,
    isRow: false,
    flex: '1 1 auto',
    alignSelf: undefined,
    alignItems: undefined,
    alignContent: undefined,
    justifyContent: undefined,
    cssClass: ''
};