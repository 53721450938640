import React, { PureComponent } from 'react';
import classNames from 'classnames';

export default class FieldLabel extends PureComponent {
    render() {
        const { id, text, isFocused, fieldType, cssClass } = this.props;
        const className = classNames('label', {
            cssClass,
            'label--focus': isFocused,
            'label--for-text': fieldType === 'text'
        });

        return text && (
            <label
                className={className}
                htmlFor={id}
            >
                {text}
            </label>
        ) || null;
    }
}

FieldLabel.defaultProps = {
    isFocused: false,
    fieldType: '',
    cssClass: ''
};