import _ from 'lodash';
import React, { PureComponent } from 'react';
import classNames from 'classnames';
import Icon from '../icon/icon';
import './tab-item.scss';

export default class TabItem extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            id: props.id || _.uniqueId()
        };

        this.updateTabItemWidth = this.updateTabItemWidth.bind(this);
        this.tabItem = React.createRef();
    }

    componentDidMount() {
        this.updateTabItemWidth();
        window.addEventListener("resize", this.updateTabItemWidth);
        window.addEventListener("load", this.updateTabItemWidth);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateTabItemWidth);
        window.removeEventListener("load", this.updateTabItemWidth);
    }

    updateTabItemWidth() {
        const { onTabItemRender, tabNumber } = this.props;

        if (onTabItemRender) {
            onTabItemRender(tabNumber, this.tabItem.current && this.tabItem.current.offsetWidth);
        }
    }

    render() {
        const { state: { id }, props: { tabNumber, title, icon, disabled, isActive, onClick, draggable, cssClass } } = this;

        const listItemClass = classNames(cssClass, {
            'is--active': isActive
        });

        return (
            <li id={id} ref={this.tabItem} className={listItemClass} >
                <a
                    title={title}
                    onClick={() => onClick(tabNumber)}
                    disabled={disabled ? true : false}
                    tabIndex={disabled ? -1 : 0}
                    draggable={draggable}
                >
                    {icon ? <Icon icon={icon} /> : null}
                    {title}
                </a>
            </li>
        );
    }
}
TabItem.defaultProps = {
    draggable: false,
    cssClass: ''
};