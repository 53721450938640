import React, { PureComponent } from 'react';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';
import './style.scss';

export default class ListItemSkeleton extends PureComponent {
    render() {
        const { hasDivider } = this.props;

        return (
            <div className={classNames('clist-skeleton__wrapper', {
                'clist__item--divider': hasDivider
            })}>
                {
                    this.createSkeletonLine([{
                        minWidth: 40,
                        maxWidth: 70
                    }, {
                        minWidth: 20,
                        maxWidth: 30
                    }])
                }
                {
                    this.createSkeletonLine([{
                        minWidth: 30,
                        maxWidth: 60
                    }, {
                        minWidth: 15,
                        maxWidth: 30
                    }])
                }
            </div>
        );
    }

    createSkeletonLine(widths) {
        return (
            <div className="clist-skeleton__lines-wrapper">
                {
                    widths.map(({ minWidth, maxWidth }, index) => {
                        const actualWidth = this.getRandomNumber(minWidth, maxWidth);

                        return (
                            <div key={index} className="clist-skeleton__line" style={{ maxWidth: actualWidth + '%' }}>
                                <Skeleton />
                            </div>
                        );
                    })
                }
            </div>
        );
    }

    getRandomNumber(min, max) {
        return Math.random() * (max - min) + min;
    }
}
