import Relay from '.';
import React, { PureComponent } from 'react';
import Spinner from '../spinner/spinner';

export default class MetadataRelay extends PureComponent {
    constructor(props) {
        super(props);
        this.state = getStateReset();
    }

    static getDerivedStateFromProps({ API, query, metadata }, { prevAPI, prevQuery, prevMetadata }) {
        if (prevAPI !== API || prevQuery !== query || prevMetadata !== metadata) {
            return getStateReset({ API, query, metadata });
        }
        return null;
    }

    render () {
        const { state: { globalName, moduleScriptURL, requestBody, error }, props: { appConfig, data, path, onValueChange, name, API, LoadingComponent } } = this;
        const parseData = typeof data === 'string' ? JSON.parse(data) : data;
        if (globalName && moduleScriptURL) {
            return (
                <Relay.Module {...{ globalName, moduleScriptURL, appConfig, data: parseData, onValueChange, name, path, LoadingComponent }} />
            );
        }
        if (error) {
            return (
                <Error {...{error, API, requestBody}} />
            );
        }
        return (
            LoadingComponent ? <LoadingComponent /> : <Spinner />
        );
    }

    async componentDidMount () {
        const { state: { requestBody }, props: { API } } = this;
        this.setState(await fetchGlobalNameAndScriptURL(API, requestBody, this.controller = new AbortController()));
    }

    async componentDidUpdate () {
        const { state: { globalName, moduleScriptURL, error, requestBody }, props: { API}, controller }= this;
        if(!error && (!globalName || !moduleScriptURL)) {
            this.setState(await fetchGlobalNameAndScriptURL(API, requestBody, controller));
        }
    }

    componentWillUnmount() {
        abortPending(this.controller);
    }
}

MetadataRelay.defaultProps = {
    query: undefined,
    metadata: undefined,
    data: undefined
};

async function fetchGlobalNameAndScriptURL(API, requestBody, { signal }) {
    let newState;
    try {
        if (!API) throw `No API prop given to metadata relay !!`;
        newState = await fetch(API, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: requestBody && 'POST' || 'GET',
            body: requestBody,
            signal: signal
        });
        if (!newState.ok) throw await newState.text();
        newState = await newState.json();
        if (!newState || !newState.globalName || !newState.moduleScriptURL) {
            throw `Wrong json response: ${JSON.stringify(newState)}.`;
        }
    } catch (err) {
        newState = {
            error: err,
            globalName: null,
            moduleScriptURL: null
        };
    }
    return newState;
}

function abortPending(controller = {}) {
    typeof controller.abort == "function" && controller.abort();
}

function Error({ error, API, requestBody }) {
    return (
        <div>
            <div>{`Error while requesting globalName and moduleScriptURL`}</div>
            <div>{`from API: ${API}`}</div>
            <div>{`with ${requestBody ? 'request body: ' : ' no request body.'}${ requestBody ? requestBody : ''}`}</div>
            <div>{`Error:`}</div>
            <div dangerouslySetInnerHTML={{ __html: error}} />
        </div>
    );
}

function getStateReset({ API, query, metadata } = {}) {
    return {
        globalName: undefined,
        moduleScriptURL: undefined,
        prevAPI: API,
        prevQuery: query,
        prevMetadata: metadata,
        requestBody: metadata || ensureQueryIsWellFormatted(query),
        error: undefined
    };
}

function ensureQueryIsWellFormatted(query) {
    if (query && query.indexOf('"query":') === -1) {
        return `{"query":"${query.replace(/"/g, '\\"')}"}`;
    }
    return query;
}