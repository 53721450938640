import _ from 'lodash';
import React, { PureComponent } from 'react';
import classNames from 'classnames';
import ReactSelect from 'react-select';
import * as JsSearch from 'js-search';
import Label from '../field_label/field_label';
import VirtualizedList from '../virtualized-list/virtualized-list';
import './select.scss';
import '../input_field/input_field.scss';
import { inputSizes, sortObjectArray, isObject } from '../../util/objectHelper';

export const validationStates = Object.freeze({
    BLANK: 'blank',
    ERROR: 'error',
    WARNING: 'warning',
    SUCCESS: 'success'
});

export default class Select extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            id: props.id || _.uniqueId(),
            scrollToRow: 0,
            filteredOptions: []
        };

        this.reactSelect = null;
        this.typingTimeout = _.debounce(filteredOptions => {
            this.updateFilteredOptions(filteredOptions);
        }, 0);

        this.bindHandlers();
        this.initializeSearch();
    }

    componentDidMount() {
        const { options } = this.props;
        this.search.addDocuments(options);
    }

    componentDidUpdate(prevProps) {
        const { options } = this.props;
        if (!_.isEqual(prevProps.options, options)) {
            this.search.addDocuments(options);
        }
    }

    bindHandlers() {
        this.getRef = this.getRef.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.updateFilteredOptions = this.updateFilteredOptions.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleMenuClose = this.handleMenuClose.bind(this);
    }

    initializeSearch() {
        const { displayField } = this.props;
        this.search = new JsSearch.Search(displayField);
        this.search.searchIndex = new JsSearch.UnorderedSearchIndex();
        this.search.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();
        this.search.addIndex(displayField);
    }

    handleInputChange(data) {
        const { displayField } = this.props;
        const filteredData = this.search.search(data);
        this.typingTimeout(sortObjectArray(filteredData, displayField));
    }

    updateFilteredOptions(filteredOptions) {
        this.setState({ filteredOptions, scrollToRow: 0 });
    }

    handleKeyDown(event) {
        const { options } = this.props;
        const { filteredOptions } = this.state;
        const availableOptions = filteredOptions.length > 0 ? filteredOptions : options;
        const currentFocusedPosition = availableOptions.indexOf(this.getFocusedOption());
        let scrollToRow;
        let navigationKeyWasPressed = true;

        switch (event.key) {
            case 'ArrowDown':
                scrollToRow =
                    currentFocusedPosition + 1 === availableOptions.length
                        ? 0
                        : currentFocusedPosition + 1;
                break;
            case 'ArrowUp':
                scrollToRow =
                    currentFocusedPosition - 1 === -1
                        ? availableOptions.length - 1
                        : currentFocusedPosition - 1;
                break;
            case 'PageDown':
                scrollToRow =
                    currentFocusedPosition + 5 >= availableOptions.length
                        ? availableOptions.length - 1
                        : currentFocusedPosition + 5;
                break;
            case 'PageUp':
                scrollToRow = currentFocusedPosition - 5 <= -1 ? 0 : currentFocusedPosition - 5;
                break;
            case 'End':
                scrollToRow = availableOptions.length - 1;
                break;
            case 'Home':
                scrollToRow = 0;
                break;
            default:
                navigationKeyWasPressed = false;
                break;
        }

        if(navigationKeyWasPressed){
            this.setState({ scrollToRow });
        }
    }

    handleMenuClose() {
        this.setState({ scrollToRow: 0 });
    }

    handleChange(newValue) {
        const { onChange, name, triggerQuery, fieldName, valueField } = this.props;

        if (onChange) {
            onChange(
                { target: { value: newValue, name, type: 'select' } },
                { triggerQuery, fieldName, valueField }
            );
        }
    }

    isOpen() {
        return this.getRef().state.menuIsOpen;
    }

    getFocusedOption() {
        return this.getRef().select.state.focusedOption;
    }

    getRef() {
        return this.reactSelect;
    }

    focus() {
        if (this.getRef()) {
            return this.getRef().focus();
        }
    }

    getSizeClass() {
        const { cssClass, size } = this.props;
        return classNames('form__item', cssClass, {
            [`form__item--${size}`]: size !== inputSizes.DEFAULT.type
        });
    }

    hasBlankValidationStatus() {
        const { validationStatus } = this.props;
        return validationStatus === validationStates.BLANK;
    }

    getValidationClass() {
        const { validationType } = this.props;
        return classNames('input input--combo', {
            [`is-${validationType}`]: !this.hasBlankValidationStatus()
        });
    }

    hasDescriptionMessage() {
        const { descriptionField, description } = this.props;
        return (descriptionField || description) && !this.hasBlankValidationStatus();
    }

    getHasDataAttribute() {
        const { options } = this.props;
        return options.length ? { 'has-data': 'true' } : null;
    }

    getDescriptionMessage(value) {
        const { descriptionField, description, isMulti } = this.props;
        return !isMulti && !description ? value[descriptionField] || '' : description;
    }

    setReactSelectRef(ref) {
        this.reactSelect = ref;
    }

    getSelectValue() {
        const { value, options, valueField } = this.props;
        const reactSelectValue =
            isObject(value) || Array.isArray(value)
                ? value
                : options.filter(option => option[valueField] === value);

        return Array.isArray(reactSelectValue) && !reactSelectValue.length ? null : reactSelectValue;
    }

    render() {
        const {
            label,
            options,
            displayField,
            valueField,
            filtering,
            placeholder,
            disabled,
            name,
            mandatory,
            isMulti
        } = this.props;
        const { id, filteredOptions } = this.state;
        const filterValue = this.getRef() && this.getRef().state.inputValue;
        const value = this.getSelectValue();

        return (
            <div className={this.getSizeClass()}>
                <div
                    id={id}
                    className={this.getValidationClass()}
                    required={mandatory}
                    role="combobox"
                    {...this.getHasDataAttribute()}
                >
                    <ReactSelect
                        id={`${id}Select`}
                        name={name}
                        ref={ref => this.setReactSelectRef(ref)}
                        value={value}
                        onChange={this.handleChange}
                        onInputChange={this.handleInputChange}
                        options={filterValue ? filteredOptions : options}
                        isSearchable={filtering ? true : false}
                        aria-label={label}
                        placeholder={placeholder}
                        isDisabled={disabled}
                        getOptionLabel={option => option[displayField]}
                        getOptionValue={option => option[valueField]}
                        clearable={false}
                        styles={{
                            control: () => ({
                                cursor: filtering ? 'text' : 'unset'
                            }),
                            singleValue: () => {}
                        }}
                        className={'select'}
                        classNamePrefix={'select'}
                        components={{
                            MenuList: VirtualizedList,
                            IndicatorSeparator: () => null,
                            ClearIndicator: () => null
                        }}
                        isMulti={isMulti}
                        isClearable={filtering ? true : false}
                        filterOption={() => true}
                        onKeyDown={this.handleKeyDown}
                        scrollToRow={this.state.scrollToRow}
                        onMenuClose={this.handleMenuClose}
                    />
                </div>

                <Label id={id} text={label} />
                {!this.hasDescriptionMessage() ? null : (
                    <div className="input-counter__wrapper">
                        <span className="input__description">{this.getDescriptionMessage(value)}</span>
                    </div>
                )}
            </div>
        );
    }
}

Select.defaultProps = {
    options: [],
    validationStatus: validationStates.BLANK,
    size: inputSizes.DEFAULT.type,
    readOnly: null,
    disabled: null,
    placeholder: null,
    mandatory: false,
    cssClass: '',
    isMulti: false
};
