import React from 'react';

export default class Nullify extends React.PureComponent {
    render () {
        return this.props.if ? null : this.props.children;
    }
}

Nullify.defaultProps = {
    if: false
};