import _ from 'lodash';
import React, { PureComponent } from 'react';
import classNames from 'classnames';
import './header-container.scss';
import IconButton from '../button/icon-button';
import * as ObjectHelper from '../../util/objectHelper';

export default class HeaderContainer extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            id: props.id || _.uniqueId(),
            isCollapsible: props.isCollapsible,
            isCollapsed: false,
            titleTemplate: props.title,
            subtitleTemplate: props.subtitle
        };
        this.toggleExpand = this.toggleExpand.bind(this);
    }

    toggleExpand() {
        this.setState(prevState => ({
            isCollapsed: !prevState.isCollapsed
        }));
    }

    render() {
        const { props: { headerData, children }, state: { id, cssClass, titleTemplate, subtitleTemplate, isCollapsible, isCollapsed } } = this;
        const titleValue = ObjectHelper.getTemplateValues(titleTemplate, headerData, true);
        const subtitleValue = ObjectHelper.getTemplateValues(subtitleTemplate, headerData, true);
        const className = classNames('header', {
            'is-collapsed': isCollapsible && isCollapsed,
            cssClass
        });

        return (
            <div className={className}>
                <div className="container-fluid">
                    <div className="header__title">
                        <h1>{titleValue}</h1>
                        <div className="header__action d-md-flex">
                            {isCollapsible ?
                                <IconButton
                                    id={id}
                                    icon={isCollapsed ? 'chevron-down' : 'chevron-up'}
                                    title={isCollapsed ? 'Expand' : 'Collapse'}
                                    cssClass="header__expand-button"
                                    onClick={this.toggleExpand}
                                /> : null}
                        </div>
                    </div>
                    <div className="header__subtitle">{subtitleValue}</div>
                </div>
                <div className="header__record" >
                    <div className="container-fluid">
                        {children}
                    </div>
                </div>
            </div>
        );
    }
}

HeaderContainer.defaultProps = {
    isCollapsible: false,
    cssClass: ''
};