import React, { PureComponent } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import './spinner.scss';

export default class Spinner extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            id: props.id || _.uniqueId()
        };
    }

    render() {
        const { cssClass, width, height } = this.props;
        const { id } = this.state;
        const className = classNames('spinner-wrapper', cssClass);

        return (
            <div id={id} className={className}>
                <div
                    className="spinner"
                    style={{
                        width,
                        height
                    }}
                >
                    <div className="double-bounce1" />
                    <div className="double-bounce2" />
                </div>
            </div>
        );
    }
}

Spinner.defaultProps = {
    width: '80px',
    height: '80px'
};
