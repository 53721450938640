import React, { PureComponent } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import Icon from '../../icon/icon';
import { isBase64, getCorrectMimeType } from '../../../util/fileHelper';
import './index.scss';

export default class Image extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            imageIsLoading: true
        };
        this.id = props.id || _.uniqueId();
        this.onLoadImageSuccess = this.onLoadImageSuccess.bind(this);
    }

    onLoadImageSuccess() {
        this.setState({
            imageIsLoading: false
        });
    }

    getImage() {
        const { source, mimeType } = this.props;
        const correctedMimeType = getCorrectMimeType(mimeType);
        return (
            source && (
                <img
                    id={this.id}
                    style={{
                        display: !this.state.imageIsLoading ? 'block' : 'none'
                    }}
                    src={
                        isBase64(source)
                            ? `data:${correctedMimeType};base64,${source}`
                            : source
                    }
                    onLoad={this.onLoadImageSuccess}
                />
            )
        );
    }

    render() {
        const { icon, cssClass } = this.props;
        return (
            <div className={classNames('clist__item-image', cssClass)}>
                {this.state.imageIsLoading && <Icon icon={icon || 'user-circle'} />}
                {this.getImage()}
            </div>
        );
    }
}

Image.defaultProps = {
    cssClass: '',
    mimeType: 'image/jpeg'
};
