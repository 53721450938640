import _ from 'lodash';
import React, { PureComponent } from 'react';
import classNames from 'classnames';
import "./checkbox.scss";

export default class Checkbox extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { id: props.id || _.uniqueId() };
    }
    render() {
        return (
            <HtmlInput {...getCheckboxProps(this) } />
        );
    }
}

export class Switch extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            id: props.id || _.uniqueId()
        };
        this.handleFocusChange = this.handleFocusChange.bind(this);
    }

    handleFocusChange({ type }) {
        this.setState({ isFocused: type === 'focus' });
    }

    render() {
        const { props: { label, inline, size, ...restOfProps }, state: { id }, handleFocusChange } = this;
        return (
            <HtmlInput {...{ ...getSwitchProps(handleFocusChange, this), id, label, ...restOfProps }} />
        );
    }
}

const HtmlInput = ({ appRole, checked, spanClassName, spanText, labelText, id, label, inputClassName,
        cssClass, labelClassName, divClassName, onChange, labelledBy, ...restOfProps }) =>
    <div className={divClassName} role={appRole}>
        <input
            type="checkbox"
            className={inputClassName}
            checked={checked}
            value={checked}
            onChange={onChange ? onChange : () => { }}
            aria-pressed={appRole ? checked : null}
            aria-labelledby={labelledBy}
            {...{ id, ...restOfProps }}
        />
        <label htmlFor={id} className={labelClassName}>
            {labelText}
        </label>
        <span className={spanClassName}>
            {spanText}
        </span>
    </div>;

const getCheckboxProps = ({ props: { inline, mixed, size, label, checked, cssClass, ...restOfProps }, state: { id } }) => {
    return {
        id,
        divClassName: classNames('form__item', 'form__item--checkbox', cssClass, {
            'form--inline': inline,
            [`form__item--checkbox--${size}`]: size
        }),
        inputClassName: classNames('input', 'input--checkbox', {
            [`input--${size}`]: size
        }),
        labelClassName: '',
        labelText: label,
        spanClassName: 'display-none',
        spanText: '',
        'aria-checked': mixed ? 'mixed' : checked,
        checked,
        ...restOfProps
    };
};

const getSwitchProps = (focusHandler, { props: { inline, cssClass, size, label } }) => {
    return {
        divClassName: classNames('form__item', 'form__item--switch', cssClass, {
            'form--inline': inline,
            [`form__item--switch--${size}`]: size
        }),
        inputClassName: classNames('switch__check', {
            [`switch__check--${size}`]: size
        }),
        labelClassName: 'switch__label',
        labelText: '',
        spanClassName: 'switch__text',
        spanText: label,
        appRole: 'application',
        role: 'switch',
        onFocus: focusHandler,
        onBlur: focusHandler
    };
};

Checkbox.defaultProps = {
    inline: false,
    mixed: false,
    ...Switch.defaultProps = {
        checked: false,
        disabled: false,
        cssClass: ''
    }
};
