import _ from 'lodash';
import React, { PureComponent } from 'react';
import classNames from 'classnames';
import MenuItem from './menu_item';

export default class MenuList extends PureComponent {
    static renderChildrenFromData({ items = [] }, focusedIndex, autoSelect, indexOffset = 0) {
        return items.map((text, index) => {
            const autoFocus = focusedIndex === indexOffset++, autoClick = autoFocus && autoSelect;
            return <MenuItem key={index} {...{ text, autoFocus, autoClick }} />;
        });
    }

    static renderChildrenFromProps(children, focusedIndex, autoSelect, indexOffset = 0) {
        return React.Children.map(children, (node) => {
            const autoFocus = focusedIndex === indexOffset++, autoClick = autoFocus && autoSelect;
            return React.cloneElement(node, { autoFocus, autoClick });
        });
    }

    constructor(props) {
        super(props);
        this.state = {
            id: props.id || _.uniqueId(),
            focusedIndex: -1,
            skipIndices: [],
            autoSelect: false,
            length: 0
        };
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        const isExpanding = props.expanded && state.focusedIndex === -1,
            isCollapsing = !props.expanded && state.focusedIndex !== -1;

        if (isExpanding) {
            const { findIndicesToSkip, findLength, arrowNavigate } = MenuList.Navigation,
                skipIndices = findIndicesToSkip(props.children),
                length = findLength(props.children, props.data);
            return {
                skipIndices,
                length,
                ...arrowNavigate(false, { focusedIndex: -1, skipIndices, length })
            };
        } else if (isCollapsing) {
            return {
                focusedIndex: -1,
                autoSelect: false
            };
        }
        return null;
    }

    handleKeyDown(event) {
        const { keyCodes: { TAB, ESC, UP, DOWN, SPACE }, arrowNavigate } = MenuList.Navigation;
        switch (event.keyCode) {
            case ESC:
                this.props.forceCollapse();
                break;
            case SPACE:
                this.setState({ autoSelect: true });
                break;
            case UP:
                this.setState(arrowNavigate(true, this.state));
                break;
            case DOWN:
                this.setState(arrowNavigate(false, this.state));
                break;
            case TAB:
                this.setState({ autoSelect: true });
                return;
            default: return;
        }
        event.preventDefault();
        event.stopPropagation();
    }

    render() {
        const { expanded, data, children, forceCollapse,
            surplusHeight, surplusWidth } = this.props;
        const { id, focusedIndex, autoSelect } = this.state;
        const style = {bottom: `${surplusHeight}px`,
            right: `${surplusWidth}px`};

        return (
            <div className="menu-list-wrapper" style={style}>
                <ul onClick={forceCollapse}
                    style={expanded ? null : { display: 'none' }}
                    className={classNames('menu', 'clist', {
                        'menu--is-open': expanded
                    })}
                    id={id}
                    aria-expanded={expanded}
                    role="listbox"
                    onKeyDown={this.handleKeyDown}
                >
                    {MenuList.renderChildrenFromProps(children, focusedIndex, autoSelect)}
                    {MenuList.renderChildrenFromData(data, focusedIndex, autoSelect, React.Children.count(children))}
                </ul>
            </div>
        );
    }
}

MenuList.Navigation = {
    keyCodes: Object.freeze({ 'UP': 38, 'DOWN': 40, 'TAB': 9, 'ESC': 27, 'SPACE': 32 }),
    arrowNavigate: (upwards, { focusedIndex, skipIndices, length }) => {
        while (skipIndices.includes(upwards ? --focusedIndex : ++focusedIndex));
        return focusedIndex >= 0 && focusedIndex < length ? { focusedIndex } : null;
    },
    findIndicesToSkip: (reactChildren) => {
        let childIndex = 0, skipIndices = [];
        React.Children.forEach(reactChildren, () => childIndex++);
        return skipIndices;
    },
    findLength: (reactChildren, { items = [] }) => React.Children.count(reactChildren) + items.length
};

MenuList.defaultProps = {
    expanded: false,
    data: { items: [] }
};

MenuList.propTypes = {
    children: function (props, propName, componentName) {
        const prop = props[propName];
        var types = ['MenuItem'];

        let error = null;
        React.Children.forEach(prop, function (child) {

            if (types.indexOf(child.type.name) === -1) {

                error = new Error(
                    '`' + componentName + '` ' +
                    'should have childs of the following types: ' +
                    ' `' + types.join('`, `') + '`.'
                );
            }
        });
        return error;
    }
};