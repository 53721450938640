import React, { PureComponent } from 'react';
import _ from 'lodash';
import './renderer-container.scss';

export default class RendererContainer extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            id: props.id || props.appId || _.uniqueId(),
            appsUrl: this.props.url || window.location.origin + "/apps/"
        };
    }

    render() {
        const { appId, metadata } = this.props;
        const { id } = this.state;
        if (appId) {
            this.renderApp(appId, metadata);
        }

        return <div className='renderer-container' id={id}></div>;
    }

    renderApp(appId, metadata) {
        const renderAppUrl = `${this.state.appsUrl}${appId}?no-cache`;
        let fetchMethod = 'GET';
        let body;

        if (metadata) {
            fetchMethod = 'POST';
            body = metadata;
        }

        fetch(renderAppUrl, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: fetchMethod,
            body: body
        })
            .then(res => res.text())
            .then((data) => {
                const bundlePath = `../../public/bundles/${appId}.bundle.js`;

                if (data.indexOf(bundlePath) !== -1) {
                    const scriptTag = document.createElement('script');
                    scriptTag.src = bundlePath;
                    document.body.appendChild(scriptTag);
                }
            });
    }
}