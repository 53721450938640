import React, { PureComponent } from 'react';
import { Page } from 'react-pdf';

export default class PageRenderer extends PureComponent {
    render() {
        const { index, data: { onLoadSuccess, onRenderSuccess, scale, rotation }, style } = this.props;
        const pageNumber = index + 1;

        return (
            <div {...{ style }}>
                <div>
                    <Page
                        pageNumber={pageNumber}
                        scale={scale}
                        rotate={rotation}
                        renderAnnotationLayer={false}
                        onLoadSuccess={onLoadSuccess}
                        onRenderSuccess={onRenderSuccess} />
                </div>
            </div>
        );
    }

}