import _ from 'lodash';
import React, { PureComponent } from 'react';
import classNames from 'classnames';
import IconButton from '../button/icon-button';
import './topbar.scss';
import TabBar from '../tab-container/tab-bar';

export default class Topbar extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            id: props.id || _.uniqueId()
        };
    }

    getLogo() {
        return (
            <>
                <span className="topbar__logo topbar__logo--desktop" />
                <span className="topbar__logo topbar__logo--mobile" />
            </>
        );
    }

    render() {
        const {
            props: { tabs, appTitle, sideDrawerButton, onClick, children, mobile, onTabClick, activeTab, showLogo, leftComponent },
            state: { id }
        } = this;
        const leftComp = (leftComponent && leftComponent.type === React.Fragment) ? leftComponent.props.children : leftComponent;

        return (
            <div
                className={classNames('topbar', {
                    topbar__mobile: mobile
                })}
                id={id}
            >
                <div className="topbar__row topbar__row--top">
                    {sideDrawerButton && (
                        <IconButton
                            icon="bars"
                            type="dark"
                            title="Toggle side drawer"
                            cssClass={classNames("topbar__menu-icon", { 'mr-0': leftComp })}
                            onClick={onClick}
                        />
                    )}
                    {leftComp && React.Children.map(leftComp ,(button, i) => i === React.Children.count(leftComp) - 1
                        ? React.cloneElement(button, { cssClass: classNames(button.props.cssClass, 'topbar__menu-icon')})
                        : React.cloneElement(button, { cssClass: classNames(button.props.cssClass, 'topbar__menu-icon mr-0')})
                    )}
                    <div className="topbar__brand">
                        {showLogo && this.getLogo()}
                        <div className="topbar__brand-name" role="heading">
                            {appTitle}
                        </div>
                    </div>
                    {tabs && (
                        <TabBar
                            id={`${id}.tab-bar`}
                            tabItems={tabs}
                            onClick={onTabClick}
                            activeTab={activeTab}
                            isDark
                            isCompressed
                            cssClass="topbar__nav"
                            activeBarIndicator={false}
                        />
                    )}
                    {children && (
                        <div className={`topbar__toolbar topbar__toolbar--${mobile ? 'mobile' : 'desktop'}`}>
                            {children}
                        </div>
                    )}
                </div>
                {tabs && (
                    <div className="topbar__row">
                        <TabBar
                            id={`${id}.tab-bar-desktop`}
                            tabItems={tabs}
                            onClick={onTabClick}
                            activeTab={activeTab}
                            isDark
                            isCompressed
                            cssClass="tabs--topbar-desktop"
                            tabItemCssClass="tabs__tab"
                        />
                    </div>
                )}
                {tabs && (
                    <TabBar
                        id={`${id}.tab-bar-mobile`}
                        tabItems={tabs}
                        onClick={onTabClick}
                        activeTab={activeTab}
                        isDark
                        cssClass="tabs--topbar-mobile"
                        tabItemCssClass="tabs__tab"
                    />
                )}
            </div>
        );
    }
}

Topbar.defaultProps = {
    activeTab: 0,
    appTitle: '',
    mobile: false,
    leftComponent: null,
    showLogo: true
};