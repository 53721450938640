import _ from 'lodash';
import classNames from 'classnames';
import React, { PureComponent } from 'react';
import Carousel, {carouselController} from '../carousel/carousel';
import { Swipeable } from 'react-swipeable';
import { getActiveSourceData, getCorrectMimeType, isBase64 } from '../../util/fileHelper';
import './slider.scss';

export default class Slider extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            id: props.id || _.uniqueId(),
            focusedBybutton: false,
            activeDocIndex: 0,
            carouselInputValue: 1
        };
        this.clearAction = this.clearAction.bind(this);
        this.handleCarouselEvent = this.handleCarouselEvent.bind(this);
        this.toggleFocusedByButtonState = this.toggleFocusedByButtonState.bind(this);
        this.updateCurrentAndAdjacentImages = this.updateCurrentAndAdjacentImages.bind(this);
        this.currentImg = {};
        this.nextImg = {};
        this.previousImg = {};
    }

    componentDidMount(){
        var mainImage = document.getElementById(`${this.state.id}MainImg`);
        if(mainImage){
            mainImage.addEventListener("webkitTransitionEnd", this.clearAction);
            mainImage.addEventListener("transitionend", this.clearAction);
        }
    }

    componentWillUnmount(){
        var mainImage = document.getElementById(`${this.state.id}MainImg`);
        if(mainImage){
            mainImage.removeEventListener("webkitTransitionEnd", this.clearAction);
            mainImage.removeEventListener("transitionend", this.clearAction);
        }
    }

    handleCarouselEvent(event, value) {
        const { source } = this.props;
        const { activeDocIndex, carouselInputValue, sliding } = this.state;
        if(!sliding){
            this.setState(carouselController(event, value, source, activeDocIndex, carouselInputValue));
        }
    }

    toggleFocusedByButtonState() {
        this.setState({ focusedBybutton: !this.state.focusedBybutton });
    }

    clearAction() {
        this.setState({action: null, sliding: false});
    }

    updateCurrentAndAdjacentImages(){
        const { source: sourceArray } = this.props,
              { activeDocIndex } = this.state;

        if(sourceArray){
            this.currentImg = this.getSpecificImage(getActiveSourceData(this.props, activeDocIndex, Slider.defaultProps.mimeType));
            this.previousImg = this.getSpecificImage(sourceArray[activeDocIndex-1] || sourceArray[sourceArray.length-1]);
            this.nextImg = this.getSpecificImage(sourceArray[activeDocIndex+1] || sourceArray[0]);
        }
    }

    getSpecificImage(element){
        const { alt, mimeType, source } = element;
        const correctedMimeType = getCorrectMimeType(mimeType || '');
        return {
            alt: alt || `Image of type ${correctedMimeType}`,
            mimeType: correctedMimeType,
            source: isBase64(source) ? `data:${correctedMimeType};base64,${source}` : source,
            unsupported: isUnsupportedMimetype(correctedMimeType)
        };
    }

    render() {
        const { source: sourceArray, height, width, cssClass, vertical } = this.props;
        const { id, carouselInputValue, action } = this.state;

        if(!action){
            this.updateCurrentAndAdjacentImages();
        }

        const className = classNames(cssClass, {
            "content-slider": !vertical,
            "content-slider--vertical": vertical
        });

        return (
            <div
                id={id}
                className={className}
                style={{ maxWidth: '100%', width: width, height: height, textAlign: 'center' }}
            >
                <div className="content-slider__wrapper">
                    <Swipeable
                        trackMouse
                        className="content-slider__content"
                        preventDefaultTouchmoveEvent
                        onSwipedLeft={!vertical ? () => this.handleCarouselEvent(Carousel.Events.FORWARDS, '') : null}
                        onSwipedRight={!vertical ? () => this.handleCarouselEvent(Carousel.Events.BACKWARDS, ''): null}
                        onSwipedUp={vertical ? () => this.handleCarouselEvent(Carousel.Events.FORWARDS, '') : null}
                        onSwipedDown={vertical ? () => this.handleCarouselEvent(Carousel.Events.BACKWARDS, '') : null}
                    >
                        <div
                            className={`previous-image${action ? `_${action}` : ''}${this.previousImg.unsupported ? emptyStateClassNames : ''}`}
                            role="img" {...propsForImg(this, this.previousImg)}
                        >
                            {emptyState(this.previousImg)}
                        </div>
                        <div
                            id={`${id}MainImg`}
                            className={`main-image${action ? `_${action}` : ''}${this.currentImg.unsupported ? emptyStateClassNames : ''}`}
                            role="img" {...propsForImg(this, this.currentImg)}
                        >
                            {emptyState(this.currentImg)}
                        </div>
                        <div
                            className={`next-image${action ? `_${action}` : ''}${this.nextImg.unsupported ? emptyStateClassNames : ''}`}
                            role="img" {...propsForImg(this, this.nextImg)}
                        >
                            {emptyState(this.nextImg)}
                        </div>
                    </Swipeable>
                    <div className="content-slider__wrapper">
                        <Carousel
                            inputValue={carouselInputValue}
                            inputMaxValue={Array.isArray(sourceArray) ? sourceArray.length : 1}
                            handler={this.handleCarouselEvent}
                            onButtonFocusChange={this.toggleFocusedByButtonState}
                        />
                    </div>
                </div>
            </div>
        );
    }
}


const isUnsupportedMimetype = (mimetype) => {
    return mimetype.indexOf('image') === -1 || mimetype.indexOf('tiff') > -1;
};

const emptyState = ({unsupported}) => {
    return unsupported
        ? <span className="inline-message__text">Image format not supported</span>
        : null;
};

const propsForImg = ({ props: { height, width, vertical }}, imageProps) => {
    return {
        type: imageProps.mimeType,
        alt: imageProps.alt,
        title: imageProps.title,
        description: imageProps.description,
        style: {
            maxWidth: Number.isInteger(width) ? `${width-(vertical ? 50 : 0)}px` : `${width}`,
            maxHeight: Number.isInteger(height) ? `${height-(vertical ? 0 : 50)}px` : `${height}`,
            height: '100%',
            width: '100%',
            backgroundImage: `url("${imageProps.source}")`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain'
        }
    };
};

const emptyStateClassNames = ' inline-message inline-message--error inline-message--medium';

Slider.defaultProps = {
    mimeType: 'image/jpeg',
    source: '',
    height: '100%',
    width: '100%',
    cssClass: ''
};