import React, { PureComponent } from 'react';
import classNames from 'classnames';
import Selector from './selector';
import Body from './body';
import Container from './micro-container';

import './style.scss';

export default class ListItem extends PureComponent {
    constructor(props) {
        super(props);

        this.state = { selected: false };

        this.onClick = props.onClick && (() => props.onClick(props.id));
        this.onSelectChange = ({ target }) => this.setState({ selected: target.checked });
    }

    render() {
        const { id, selectable, isActive, draggable, onKeyPress } = this.props;
        const { data, textToHighlight, primaryTitle, primarySubtitle, secondaryTitle, secondarySubtitle, left: Left, right: Right, bottom: Bottom } = this.props;
        const { selected } = this.state;
        const { onClick, onSelectChange } = this;
        return (
            <li id={id} className={getCss(this.props)}>
                <a
                    tabIndex="1"
                    className={classNames('clist__item-link flex-column', { 'active': isActive })}
                    role="listitem"
                    onClick={onClick}
                    onKeyPress={onKeyPress}
                    draggable={draggable}
                >
                    <div className="d-flex">
                        {Left && <Container cssClass={'mr-0 clist__left-container'}><Left parentId={id} {...data}/></Container>}
                        <Body
                            {...{ primaryTitle, primarySubtitle, secondaryTitle, secondarySubtitle }}
                            data={data}
                            textToHighlight={textToHighlight}
                        />
                        {Right && <Container cssClass={'ml-0 clist__right-container'}><Right parentId={id} {...data}/></Container>}
                        {selectable && <Selector {...{ id, selected, onSelectChange }}/>}
                    </div>
                    {Bottom &&
                        <div className="d-flex justify-content-end">
                            <Container><Bottom parentId={id} {...data}/></Container>
                        </div>
                    }
                </a>
            </li>
        );
    }
}

ListItem.defaultProps = {
    draggable: false,
    right: null,
    left: null,
    bottom: null
};

function getCss({ hasDivider, hasDot, size, statusBar }) {
    return classNames('clist__item', {
        'clist__item--divider': hasDivider,
        'clist__item--dot': hasDot,
        [`clist__item--${size}`]: itemSize[size],
        [`clist__item--bar-${statusBar}`]: itemStatusBar[statusBar]
    });
}

const itemSize = Object.freeze({
    'medium': 'clist__item--medium',
    'large': 'clist__item--large'
});

const itemStatusBar = Object.freeze({
    'success': 'clist__item--bar-success',
    'warning': 'clist__item--bar-warning',
    'alert': 'clist__item--bar-alert'
});