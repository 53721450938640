import React, { PureComponent } from 'react';

export default class TextHighlighter extends PureComponent {
    render () {
        const { text, children, highlightCss } = this.props;

        if (!text) {
            return children;
        }

        const parts = children.toString().split(new RegExp(`(${text})`, 'gi'));
        const getPartCss = part => part.toLowerCase() === text.toLowerCase() ? highlightCss : '';

        return (
            <span>
                {parts.map(getPartCss).map((css, i) =><span key={i} className={css}>{parts[i]}</span>)}
            </span>
        );
    }
}

TextHighlighter.defaultProps = {
    highlightCss: 'search__highlight',
    children: ''
};