import Metadata from './metadata';
import Module from './module';
import ControlledView from './controlled-view';

export default {
    Metadata,
    Module,
    ControlledView
};

