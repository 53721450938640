import _ from 'lodash';
import React, { PureComponent } from 'react';
import classNames from 'classnames';
import TabItem from './tab-item';
import PerfectScrollbar from 'react-perfect-scrollbar';

export default class TabBar extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            id: props.id || _.uniqueId(),
            tabItemsWidth: {}
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleTabItemRender = this.handleTabItemRender.bind(this);
    }

    getActiveTabTransformWidth() {
        let sum = 0;

        _.forEach(this.state.tabItemsWidth, (width, tabNumber) => {
            if (tabNumber < this.props.activeTab) {
                sum += width;
            }
        });

        return sum;
    }

    isActiveTab(tabNumber) {
        return tabNumber === this.props.activeTab;
    }

    handleClick(tabNumber) {
        const { onClick } = this.props;

        if (onClick) {
            onClick(tabNumber);
        }
    }

    handleTabItemRender(tabNumber, tabItemWidth) {
        const { tabItemsWidth } = this.state;

        tabItemsWidth[tabNumber] = tabItemWidth;

        this.setState({ tabItemsWidth });
        this.forceUpdate();
    }

    render() {
        const { state: { id, tabItemsWidth }, props: { cssClass, activeBarIndicator, tabItems, isCompressed, isDark, tabItemCssClass, activeTab } } = this;

        const listClass = classNames('tabs', {
            'tabs--with-icon': tabItems && tabItems.find((tabItem) => tabItem.icon),
            'tabs--compressed': isCompressed,
            'tabs--dark': isDark
        });

        const divClass = classNames(cssClass, {
            'tab-wrapper': !cssClass,
            'tabs--dark': isDark
        });

        const activeBarStyle = {
            width: tabItemsWidth[activeTab],
            transform: 'translateX(' + this.getActiveTabTransformWidth() + 'px)'
        };

        return (
            <div className={divClass}>
                <PerfectScrollbar>
                    <ul className={listClass} id={id}>
                        {_.map(tabItems, (tabItem, index) => {
                            const tabItemId = `${id}.${index}`;
                            return (
                                <TabItem
                                    key={index}
                                    id={tabItemId}
                                    tabNumber={index}
                                    title={tabItem.title}
                                    icon={tabItem.icon}
                                    disabled={tabItem.disabled}
                                    isActive={this.isActiveTab(index)}
                                    onClick={this.handleClick}
                                    onTabItemRender={this.handleTabItemRender}
                                    cssClass={tabItemCssClass}
                                />
                            );
                        })}
                    </ul>
                    { activeBarIndicator && <div className={"tabs__tab-active-bar"} style={activeBarStyle} /> }
                </PerfectScrollbar>
            </div>
        );
    }
}

TabBar.defaultProps = {
    activeTab: 0,
    isCompressed: false,
    isDark: false,
    cssClass: '',
    tabItemCssClass: '',
    activeBarIndicator: true
};