import { PureComponent } from 'react';

export default class NavigationRenderer extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const { children, active } = this.props;

        var jsx = null;

        children.forEach((child) => {
            if (child.key === active) {
                jsx = child;
            }
        });

        return jsx;
    }
}