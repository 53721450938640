import _ from 'lodash';
import React, { PureComponent } from 'react';
import InputField from './input_field';

export default class SearchField extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            id: props.id || _.uniqueId()
        };
        this.handleChange = this.handleChange.bind(this);
        this.typingTimeout = _.debounce((value, fieldName) => {props.onChange(value, fieldName);}, 300);
    }

    handleChange({target: {value}}){
        const {fieldName} = this.props;
        this.setState({ value });
        this.typingTimeout(value, fieldName);
    }

    render() {
        const { state: { value }, props: { onChange, cssClass, fieldName, ...restOfProps } } = this;

        return (
            <InputField
                onChange={this.handleChange}
                value={value}
                cssClass={cssClass ? `${cssClass} search__field` : 'search__field'}
                dataType={'search'}
                {...restOfProps}
            />
        );
    }
}