import React, { PureComponent } from 'react';
import Checkbox from '../../../checkbox/checkbox';

export default class ListItemSelector extends PureComponent {
    render() {
        const { id, selected, onSelectChange } = this.props;
        return (
            <div className="clist__item-inner-wrapper pl-0 pr-0 align-self-center">
                <Checkbox
                    size="small"
                    labelledBy={id}
                    checked={selected}
                    onChange={onSelectChange}
                />
            </div>
        );
    }
}

ListItemSelector.defaultProps = {
    selected: false,
    onSelectChange() {}
};