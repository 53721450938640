import _ from 'lodash';
import React, { PureComponent } from 'react';
import InputField from './input_field';
import './input-field-editor.scss';

export default class InputFieldEditor extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            id: props.id || _.uniqueId(),
            value: props.value || ''
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange({ target: { value } }) {
        this.setState({ value });
    }

    getValue() {
        return this.state.value;
    }

    afterGuiAttached() {
        const { eGridCell, cellStartedEdit } = this.props;

        this.inputElement = eGridCell.getElementsByClassName('input')[0];

        if (cellStartedEdit) {
            this.focusInput();
        }
    }

    focusIn() {
        this.focusInput();
    }

    focusInput() {
        this.inputElement.focus();
        this.inputElement.select();
    }

    render() {
        const { id, value } = this.state;

        return (
            <div className="input-field-editor">
                <InputField
                    id={id}
                    value={value}
                    onChange={this.handleChange}
                />
            </div>
        );
    }
}
