import React, { PureComponent } from 'react';
import IconButton from '../button/icon-button';
import InputField from '../input_field/input_field';
import classNames from 'classnames';
import { LiveMessage } from 'react-aria-live';
import _ from 'lodash';
import './carousel.scss';

export default class Carousel extends PureComponent {

    constructor(props) {
        super(props);
        Carousel.setControlHandler(this);
        this.state = {
            ariaMessage: `Image 1 of ${props.inputMaxValue}`
        };
    }

    static setControlHandler(instance) {
        const { BACKWARDS, FORWARDS, INPUT_PRESS_ENTER, INPUT_CHANGE, INPUT_BLUR, DOT_CLICK } = this.Events;
        const { handler, onButtonFocusChange } = instance.props;
        instance.onBackwardsClick = () => handler(BACKWARDS);
        instance.onForwardsClick = () => handler(FORWARDS);
        instance.onInputChange = event => {
            handler(INPUT_CHANGE, event.target.value);
            instance.setState({ typing: true });
        };
        instance.onInputBlur = () => {
            onButtonFocusChange();
            handler(INPUT_BLUR);
            instance.setState({ typing: false });
        };
        instance.onInputKeyPress = event => {
            const { inputValue, inputMaxValue } = instance.props;
            if (event.key === 'Enter') {
                handler(INPUT_PRESS_ENTER);
                inputValue <= inputMaxValue && inputValue > 0
                    ? instance.setState({ typing: false, pressEnter: true })
                    : instance.setState({ ariaMessage: `Invalid number. Please enter a value between 1 and ${inputMaxValue}` });
            }
        };
        instance.onDotClick = (position) => handler(DOT_CLICK, position);
    }

    componentDidUpdate(prevProps) {
        const { inputValue, inputMaxValue } = this.props;
        const { typing, pressEnter } = this.state;
        if ((prevProps.inputValue !== inputValue && !typing) || pressEnter) {
            this.setState({ ariaMessage: `Picture ${inputValue} of ${inputMaxValue}`, pressEnter: false });
        }
    }

    getNumberPagination() {
        const { inputMaxValue, inputValue, onButtonFocusChange } = this.props;
        const { ariaMessage } = this.state;

        return (
            <div className="number-pagination">
                <InputField
                    type="border"
                    dataType={'number'}
                    size='small'
                    aria-label={ariaMessage}
                    value={inputValue}
                    onKeyPress={this.onInputKeyPress}
                    onChange={this.onInputChange}
                    onBlur={this.onInputBlur}
                    onFocus={onButtonFocusChange}
                />
                /
                <InputField
                    dataType={'number'}
                    size='small'
                    value={inputMaxValue}
                    readOnly={true}
                    disabled={true}
                />
            </div>
        );
    }

    getDotForPosition(position) {
        const isTheActiveDot = position === this.props.inputValue;
        const className = classNames(
            "content-slider__bullets-item",
            { "content-slider__bullets-item--active": isTheActiveDot }
        );

        return <div key={position}
            className={className}
            onClick={!isTheActiveDot ? () => this.onDotClick(position) : undefined}>
        </div>;
    }

    getDotsPagination() {
        return (
            <div className="dots-pagination content-slider__bullets">
                {_.times(this.props.inputMaxValue, (iteration) => this.getDotForPosition(iteration + 1))}
            </div>
        );
    }

    render() {
        const { inputMaxValue, maxNumberOfDots, hideIfSingleElement, onBlur, onFocus, onButtonFocusChange, useDotsAsPagination } = this.props;
        const { ariaMessage } = this.state;
        const showDotsPagination = inputMaxValue <= maxNumberOfDots;

        return (
            <div className="carousel-controls" style={hideIfSingleElement && inputMaxValue < 2 ? { visibility: 'hidden' } : null} {...{ onFocus, onBlur }}>
                <IconButton
                    type="border"
                    icon="chevron-left"
                    title="Previous"
                    alt="Previous Item"
                    onClick={this.onBackwardsClick}
                    onFocus={onButtonFocusChange}
                    onBlur={onButtonFocusChange}
                />
                {useDotsAsPagination && showDotsPagination ? this.getDotsPagination() : this.getNumberPagination()}
                <IconButton
                    type="border"
                    icon="chevron-right"
                    title="Next"
                    alt="Next Item"
                    onClick={this.onForwardsClick}
                    onFocus={onButtonFocusChange}
                    onBlur={onButtonFocusChange}
                />
                <LiveMessage message={ariaMessage} aria-live="polite" />
            </div>
        );
    }
}

Carousel.defaultProps = {
    inputValue: 1,
    inputMaxValue: 1,
    handler: () => { },
    hideIfSingleElement: true,
    maxNumberOfDots: 5,
    useDotsAsPagination: true
};

Carousel.Events = Object.freeze({ 'FORWARDS': 1, 'BACKWARDS': 2, 'INPUT_CHANGE': 3, 'INPUT_PRESS_ENTER': 4, 'INPUT_BLUR': 5, 'DOT_CLICK': 6 });

export function carouselController(event, value, source, activeDocIndex, carouselInputValue) {
    const { BACKWARDS, FORWARDS, INPUT_PRESS_ENTER, INPUT_CHANGE, INPUT_BLUR, DOT_CLICK } = Carousel.Events;
    let finalDoc;
    let firstDoc;
    let newState = {};

    switch (event) {
        case FORWARDS:
            finalDoc = activeDocIndex < source.length - 1;

            newState.activeDocIndex = finalDoc ? activeDocIndex + 1 : 0;
            newState.carouselInputValue = finalDoc ? activeDocIndex + 2 : 1;
            newState.action = 'foward';
            newState.sliding = true;
            break;
        case BACKWARDS:
            firstDoc = activeDocIndex > 0;

            newState.activeDocIndex = firstDoc ? activeDocIndex - 1 : source.length - 1;
            newState.carouselInputValue = firstDoc ? activeDocIndex : source.length;
            newState.action = 'backward';
            newState.sliding = true;
            break;
        case INPUT_CHANGE:
            newState = { carouselInputValue: value };
            break;
        case INPUT_BLUR:
            newState = { carouselInputValue: activeDocIndex + 1 };
            break;
        case INPUT_PRESS_ENTER:
            newState = carouselInputValue <= source.length && carouselInputValue > 0 ? { activeDocIndex: carouselInputValue - 1, action: undefined } : null;
            break;
        case DOT_CLICK:
            newState = { activeDocIndex: value - 1, carouselInputValue: value };
            break;
        default: newState = null;
    }

    return newState;
}