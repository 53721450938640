import React, { PureComponent } from 'react';
import Fullscreen from "react-full-screen";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Document, pdfjs } from 'react-pdf';
import { PasswordResponses } from 'pdfjs-dist';
import { VariableSizeList } from 'react-window';
import classNames from 'classnames';
import _ from 'lodash';
import Tiff from 'tiff.js';
import b64toBlob from 'b64-to-blob';
import saveAs from 'file-saver';
import Toolbar from './toolbar';
import Carousel, {carouselController} from '../carousel/carousel';
import PageRenderer from './page_renderer';
import Nullify from '../nullify';
import InputField, { validationStates } from '../input_field/input_field';
import { getActiveSourceData, getCorrectMimeType, isBase64 } from '../../util/fileHelper';
import Button from '../button/button';
import MenuItem from '../menu/menu_item';
import Menu from '../menu/menu';
import IconButton from '../button/icon-button';
import Modal from '../modal/modal';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './style/index.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const ZOOM_COEFFICIENT = 3 / 4;

export default class DocumentViewer extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            id: props.id || _.uniqueId(), focusedByMouse: false, focusedBybutton: false, imgWidth: 250, imgHeight: 250,
            fullScreen: false, toggledFullScreen: false, rotation: 0, scale: 1, fitMode: 'width',
            containerHeight: null, pdf: null, currentPage: 1, cachedPageDimensions: null,
            activeDocIndex: 0,
            carouselInputValue: 1,
            showPasswordModal: false,
            pdfPassword: '',
            pdfPasswordValidationStatus: validationStates.BLANK,
            pdfPasswordDescription: '',
            attachments: null
        };

        DocumentViewer.bindHandlers.call(this);
        DocumentViewer.setReferences.call(this);
    }

    static bindHandlers() {
        this.downloadFile = this.downloadFile.bind(this);
        this.openDocumentsApp = this.openDocumentsApp.bind(this);
        this.rotate = this.rotate.bind(this);
        this.toggleFocusedByButtonState = this.toggleFocusedByButtonState.bind(this);
        this.setFocusedState = this.setFocusedState.bind(this);
        this.zoomInImage = this.zoomInImage.bind(this);
        this.zoomOutImage = this.zoomOutImage.bind(this);
        this.zoomInDocument = this.zoomInDocument.bind(this);
        this.zoomOutDocument = this.zoomOutDocument.bind(this);
        this.fitImage = this.fitImage.bind(this);
        this.fitDocument = this.fitDocument.bind(this);
        this.onLoadImage = this.onLoadImage.bind(this);
        this.onDocumentLoad = this.onDocumentLoad.bind(this);
        this.printFile = this.printFile.bind(this);
        this.computeRowHeight = this.computeRowHeight.bind(this);
        this.updateCurrentVisiblePage = this.updateCurrentVisiblePage.bind(this);
        this.handleCarouselEvent = this.handleCarouselEvent.bind(this);
        this.handleToolbarEvent = this.handleToolbarEvent.bind(this);
        this.toggleFullScreen = this.toggleFullScreen.bind(this);
        this.onFullscreenChange = this.onFullscreenChange.bind(this);
        this.onSourceChange = this.onSourceChange.bind(this);
        this.onPassword = this.onPassword.bind(this);
        this.handlePdfPasswordChange = this.handlePdfPasswordChange.bind(this);
        this.handleEnterPassword = this.handleEnterPassword.bind(this);
        this.handlePdfPasswordKeyDown = this.handlePdfPasswordKeyDown.bind(this);
    }

    static setReferences() {
        this.setDocumentContainerRef = (ref) => {
            if (ref) {
                ref.style.setProperty('overflow-x', 'hidden', 'important');
                ref.style.setProperty('overflow-y', 'hidden', 'important');
            }
            this.documentContainerRef = ref;
        };
        this.imgComponent = React.createRef();
        this.iFrameToPrintPdf = React.createRef();
        this.iFrameToPrintImg = React.createRef();
        this.fabParent = React.createRef();
    }

    onLoadImage() {
        this.fitImage();
    }

    fitImage() {
        const { naturalWidth, naturalHeight } = this.imgComponent.current;
        const { clientWidth, clientHeight } = this.documentContainerRef;
        const imgAspectRatio = naturalWidth / naturalHeight;
        const clientAspectRatio = clientWidth / clientHeight;
        const fitToDocumentContainerWidth = clientAspectRatio < imgAspectRatio;

        this.setState({
            rotation: 0,
            imgWidth: fitToDocumentContainerWidth ? clientWidth : clientHeight * imgAspectRatio,
            imgHeight: fitToDocumentContainerWidth ? clientWidth / imgAspectRatio : clientHeight
        });
    }

    fitDocument() {
        this.setState({
            rotation: 0,
            triggerFit: true,
            cachedPageDimensions: null
        });
    }

    onDocumentLoad(pdf) {
        pdf.getAttachments().then((attachments) => {
            this.setState({
                attachments
            });
        });

        this.setState({
            rotation: 0,
            triggerFit: true,
            cachedPageDimensions: null,
            pdf,
            fitMode: this.state.fullScreen ? 'page' : 'width',
            showPasswordModal: false,
            pdfPassword: ''
        });
    }

    onSourceChange() {
        this.setState({
            activeDocIndex: 0,
            carouselInputValue: 1,
            showPasswordModal: false,
            attachments: null,
            pdfPassword: ''
        });
    }

    zoomInImage() {
        this.setState({ imgHeight: this.state.imgHeight / ZOOM_COEFFICIENT, imgWidth: this.state.imgWidth / ZOOM_COEFFICIENT });
    }

    zoomOutImage() {
        this.setState({ imgHeight: this.state.imgHeight * ZOOM_COEFFICIENT, imgWidth: this.state.imgWidth * ZOOM_COEFFICIENT });
    }

    zoomInDocument() {
        this.setState({
            scale: this.state.scale / ZOOM_COEFFICIENT,
            cachedPageDimensions: null
        });
    }

    zoomOutDocument() {
        this.setState({
            scale: this.state.scale * ZOOM_COEFFICIENT,
            cachedPageDimensions: null
        });
    }

    setFocusedState(focused) {
        this.setState({ focusedByMouse: focused });
    }

    toggleFocusedByButtonState() {
        this.setState({ focusedBybutton: !this.state.focusedBybutton });
    }

    rotate() {
        this.setState({
            rotation: (this.state.rotation + 90) % 360,
            cachedPageDimensions: null
        });
    }

    toggleFullScreen() {
        this.setState({
            fullScreen: !this.state.fullScreen
        });
    }

    onFullscreenChange(fullScreen) {
        this.setState({
            fitMode: !this.state.fullScreen ? 'width' : 'page',
            fullscreenToggled: true,
            fullScreen
        });
    }

    printFile() {
        const { source, mimeType } = getActiveSourceData(this.props, this.state.activeDocIndex, DocumentViewer.defaultProps.mimeType);

        const isPDF = (mimeType === 'application/pdf');
        var ifrm;

        if (!isPDF) {
            ifrm = this.iFrameToPrintImg.current;
            const contentWindow = ifrm.contentWindow,
                content = this.documentContainerRef;

            contentWindow.document.open();
            contentWindow.document.write(content.innerHTML);
            contentWindow.document.close();
        }
        else {
            ifrm = this.iFrameToPrintPdf.current;
            const isbase64 = isBase64(source),
                correctMimeType = getCorrectMimeType(mimeType),
                blobUrl = isbase64 ? URL.createObjectURL(b64toBlob(source, correctMimeType)) : source;
            ifrm.src = blobUrl;
        }

        ifrm.onload = () => {
            try {
                ifrm.contentWindow.focus();
                ifrm.contentWindow.print();
            }
            catch {
                if (ifrm.src) {
                    window.open(ifrm.src, '_blank');
                }
            }
        };
    }

    downloadFile() {
        const { source, mimeType, description } = getActiveSourceData(this.props, this.state.activeDocIndex, DocumentViewer.defaultProps.mimeType),
            mimeTypeWithoutXml = mimeType.split('+'),
            isbase64 = isBase64(source),
            extensionFile = mimeTypeWithoutXml[0].substring(mimeTypeWithoutXml[0].indexOf('/') + 1, mimeTypeWithoutXml[0].length),
            convertedSource = isbase64 ? b64toBlob(source, mimeType) : source;

        saveAs(convertedSource, `${description}.${extensionFile}`);
    }

    downloadAttach(filename) {
        const { attachments } = this.state;
        const binaryData = [];
        const attach = attachments[filename];

        binaryData.push(attach.content);

        const blob = new Blob(binaryData);
        const objectUrl = window.URL.createObjectURL(blob);

        saveAs(objectUrl, attach.filename);
    }

    openDocumentsApp() {
        window.open(encodeURI(this.props.documentsAppUrl), '_blank');
    }

    cachePageDimensions() {
        const { pdf, rotation } = this.state;

        const promises = Array
            .from({ length: pdf.numPages }, (v, i) => i + 1)
            .map(pageNumber => pdf.getPage(pageNumber));
        const isPortrait = rotation % 180 === 0;
        const bottomMargin = 12;
        let containerHeight = 0;
        let scale = this.state.scale;
        Promise.all(promises).then(pages => {
            const pageDimensions = [];
            for (const page of pages) {
                if (this.state.triggerFit) {
                    if (this.state.fitMode === 'width') {
                        scale = this.documentContainerRef.clientWidth / page.view[2];
                    } else {
                        scale = this.documentContainerRef.clientHeight / page.view[3];
                    }
                }
                let width, height;
                if (isPortrait) {
                    width = page.view[2] * scale;
                    height = page.view[3] * scale + bottomMargin;
                } else {
                    width = page.view[3] * scale;
                    height = page.view[2] * scale + bottomMargin;
                }
                pageDimensions[page.pageIndex] = { width, height };
                containerHeight += height;
            }

            this.setState({
                cachedPageDimensions: pageDimensions,
                containerHeight: containerHeight,
                scale: scale,
                triggerFit: false,
                fitMode: this.state.triggerFit
                    ? this.state.fitMode === 'width'
                        ? 'page'
                        : 'width'
                    : this.state.fitMode
            });
        });
    }

    computeRowHeight(index) {
        return this.state.cachedPageDimensions ? this.state.cachedPageDimensions[index].height : 768;
    }

    updateCurrentVisiblePage({ visibleStopIndex }) {
        this.setState({ currentPage: visibleStopIndex + 1 });
    }

    handleCarouselEvent(event, value) {
        const { source } = this.props;
        const { activeDocIndex, carouselInputValue } = this.state;
        this.setState(carouselController(event, value, source, activeDocIndex, carouselInputValue));
    }

    handleToolbarEvent(event) {
        const { PRINT, DOWNLOAD, NEW_TAB, FULLSCREEN } = Toolbar.Top.Events;
        const { ROTATE, FIT, ZOOM_IN, ZOOM_OUT } = Toolbar.Bottom.Events;
        const isPDF = getActiveSourceData(this.props, this.state.activeDocIndex, DocumentViewer.defaultProps.mimeType).mimeType === 'application/pdf';
        switch (event) {
            case PRINT:
                return this.printFile();
            case DOWNLOAD:
                return this.downloadFile();
            case NEW_TAB:
                return this.openDocumentsApp();
            case FULLSCREEN:
                return this.toggleFullScreen();
            case ROTATE:
                return this.rotate();
            case FIT:
                return isPDF ? this.fitDocument() : this.fitImage();
            case ZOOM_IN:
                return isPDF ? this.zoomInDocument() : this.zoomInImage();
            case ZOOM_OUT:
                return isPDF ? this.zoomOutDocument() : this.zoomOutImage();
            default: return null;
        }
    }

    onPassword(callback, reason) {
        this.setState({
            showPasswordModal: true,
            passwordCallback: callback,
            pdfPasswordValidationStatus: reason === PasswordResponses.INCORRECT_PASSWORD ? validationStates.ERROR : validationStates.BLANK,
            pdfPasswordDescription: reason === PasswordResponses.INCORRECT_PASSWORD ? 'Incorrect password' : ''
        });
    }

    handlePdfPasswordChange({ target: { value } }) {
        this.setState({
            pdfPassword: value,
            pdfPasswordValidationStatus: validationStates.BLANK,
            pdfPasswordDescription: ''
        });
    }

    handleEnterPassword() {
        const { passwordCallback, pdfPassword } = this.state;
        if (pdfPassword) {
            passwordCallback(pdfPassword);
        } else {
            this.setState({
                pdfPasswordValidationStatus: validationStates.WARNING,
                pdfPasswordDescription: 'Password field cannot be empty'
            });
        }
    }

    handlePdfPasswordKeyDown(event) {
        switch (event.keyCode) {
            case 13:
                this.handleEnterPassword();
                break;
            default: break;
        }
    }

    createAttachmentMenu() {
        const { attachments } = this.state;

        return (
            <div className="fab-container">
                <Menu parentRef = {this.fabParent} button={
                    <IconButton
                        name="paperclip"
                        icon={"paperclip"}
                        type="dark"
                    />
                }>
                    {Object.keys(attachments).map((filename) =>
                        <MenuItem
                            key={filename}
                            text={filename}
                            onClick={() => this.downloadAttach(filename)}
                        />
                    )}
                </Menu>
            </div>
        );
    }

    render() {
        const { source: sourceArray, height, width, cssClass } = this.props,
            { id, focusedByMouse, attachments, activeDocIndex, focusedBybutton, rotation, fullScreen, fitMode, scale, cachedPageDimensions, containerHeight, pdf, carouselInputValue, showPasswordModal, pdfPassword } = this.state,
            { source, mimeType, title, description } = getActiveSourceData(this.props, activeDocIndex, DocumentViewer.defaultProps.mimeType),
            isPDF = (mimeType === 'application/pdf'),
            correctMimeType = getCorrectMimeType(mimeType),
            src = isBase64(source) ? `data:${correctMimeType};base64,${source}` : source,
            tiffImagesAsPngArray = convertTiffToBase64PngImageArray(mimeType, source);

        const wrapperClass = classNames('document-viewer__wrapper', cssClass, {
            'is-focused': focusedByMouse,
            'is-fullscreen': fullScreen,
            'button-is-focused': focusedBybutton
        });
        const imageWrapperClass = classNames(`rotate-${rotation}`, {
            'document-viewer__image-wrapper': !tiffImagesAsPngArray,
            'document-viewer__tiff-wrapper': tiffImagesAsPngArray,
            'empty-source': !tiffImagesAsPngArray && !source
        });

        return (
            <div className={wrapperClass} onMouseEnter={() => this.setFocusedState(true)} onMouseLeave={() => this.setFocusedState(false)}
                style={{ maxWidth: '100%', width: width, height: height }}
                id={id}
            >
                <Fullscreen enabled={fullScreen} onChange={this.onFullscreenChange} >
                    <div className="document-viewer__content-wrapper" ref={this.fabParent}>
                        <Toolbar.Top nullifyIf={!source}
                            {...{ title, description, fullScreen }}
                            handler={this.handleToolbarEvent}
                            onButtonFocusChange={this.toggleFocusedByButtonState}
                        />
                        {attachments && this.createAttachmentMenu()}
                        <PerfectScrollbar
                            style={{ width: '100%', maxWidth: '100%' }}
                            className={imageWrapperClass}
                            containerRef={this.setDocumentContainerRef}
                        >
                            <Nullify if={!isPDF}>
                                <Document
                                    file={src}
                                    error={this.props.alt}
                                    noData={this.props.alt}
                                    onLoadSuccess={this.onDocumentLoad}
                                    onPassword={this.onPassword}
                                >
                                    <Nullify if={!cachedPageDimensions}>
                                        <VariableSizeList
                                            height={containerHeight}
                                            itemCount={pdf && pdf.numPages}
                                            itemSize={this.computeRowHeight}
                                            itemData={{
                                                scale,
                                                rotation
                                            }}
                                            onItemsRendered={this.updateCurrentVisiblePage}
                                            style={{ overflow: 'visible', maxWidth: '100%' }}
                                        >
                                            {PageRenderer}
                                        </VariableSizeList>
                                    </Nullify>
                                </Document>
                            </Nullify>
                            <Nullify if={!tiffImagesAsPngArray}>
                                {tiffImagesAsPngArray && tiffImagesAsPngArray.map((src, key) =>
                                    <img key={key} ref={this.imgComponent} onLoad={this.onLoadImage} src={src} className={"tiff"} {...propsForImg(this, correctMimeType)} />
                                )}
                            </Nullify>
                            <Nullify if={isPDF || tiffImagesAsPngArray} >
                                <img src={src} ref={this.imgComponent} onLoad={this.onLoadImage} className={"image"} {...propsForImg(this, correctMimeType)} />
                            </Nullify>
                            <Modal
                                showHeader={false}
                                showModal={showPasswordModal}
                                fullScreen={false}
                                cssClass="modal-pdf-password"
                                footer={
                                    <React.Fragment>
                                        <Button text="OK" size="small" type="happy" onClick={this.handleEnterPassword} />
                                    </React.Fragment>
                                }
                            >
                                <InputField
                                    dataType="password"
                                    value={pdfPassword}
                                    label="Pdf password"
                                    name='modalPdfPassword'
                                    onChange={this.handlePdfPasswordChange}
                                    validationStatus={this.state.pdfPasswordValidationStatus}
                                    description={this.state.pdfPasswordDescription}
                                    onKeyDown={this.handlePdfPasswordKeyDown}
                                />
                            </Modal>
                        </PerfectScrollbar>
                        <Toolbar.Bottom nullifyIf={!source} handler={this.handleToolbarEvent} onButtonFocusChange={this.toggleFocusedByButtonState} isPDF={isPDF} fitMode={fitMode}>
                            <div className={"document-viewer__carousel-toolbar"}>
                                <Carousel inputValue={carouselInputValue} inputMaxValue={Array.isArray(sourceArray) ? sourceArray.length : 1} handler={this.handleCarouselEvent} onButtonFocusChange={this.toggleFocusedByButtonState} />
                            </div>
                        </Toolbar.Bottom>
                    </div>
                </Fullscreen>
                <iframe style={{ display: "none" }} ref={this.iFrameToPrintPdf} />
                <iframe style={{ display: "none" }} ref={this.iFrameToPrintImg} />
            </div>
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.source !== this.props.source) {
            this.onSourceChange();
        }

        const isPDF = getActiveSourceData(this.props, this.state.activeDocIndex, DocumentViewer.defaultProps.mimeType).mimeType === 'application/pdf';
        if (this.state.fullscreenToggled) {
            if (isPDF) {
                this.fitDocument();
            } else {
                this.fitImage();
            }
            this.setState({ fullscreenToggled: false });
        } else if (isPDF && this.state.cachedPageDimensions === null && this.state.pdf) {
            this.cachePageDimensions();
        }

        if(prevState.activeDocIndex !== this.state.activeDocIndex){
            this.setState({
                attachments: null,
                showPasswordModal: false
            });
        }
    }
}

const propsForImg = ({ props: { alt }, state: { imgWidth, imgHeight } }, correctMimeType) => {
    return {
        type: correctMimeType,
        alt,
        style: {
            maxWidth: `${imgWidth}px`,
            minWidth: `${imgWidth}px`,
            width: `${imgWidth}px`,
            height: `${imgHeight}px`,
            maxHeight: `${imgHeight}px`,
            minHeight: `${imgHeight}px`
        }
    };
};

const base64ToArrayBuffer = ((base64) => {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
});

const convertTiffToBase64PngImageArray = ((mimeType, source) => {
    if (mimeType.indexOf('tiff') == -1) return null;

    var tiffImagesAsPngArray = [],
        tiff = new Tiff({ buffer: base64ToArrayBuffer(source) }),
        numberOfImages = tiff.countDirectory();

    for (var i = 0; i < numberOfImages; ++i) {
        tiff.setDirectory(i);
        tiffImagesAsPngArray.push(tiff.toCanvas().toDataURL());
    }

    return tiffImagesAsPngArray;
});

DocumentViewer.defaultProps = {
    mimeType: 'image/jpeg',
    source: '',
    height: '100%',
    width: '100%',
    cssClass: '',
    title: '',
    description: '',
    documentsAppUrl: 'https://u4next-renderer-dev.azurewebsites.net/documents'
};