import React, { PureComponent } from 'react';
import './default.scss';
import Hyperlink from '../../hyperlink/hyperlink';
import * as ObjectHelper from '../../../util/objectHelper';

export default class HyperlinkRenderer extends PureComponent {
    render() {
        const { value, config, rawData, rowIndex } = this.props;
        const { currentWindow, text } = config || {};

        return (
            <Hyperlink
                cssClass="ag-cell-default"
                url={value}
                target={currentWindow ? '_self' : '_blank'}
                text={ObjectHelper.getTemplateValues(text || value, (rawData ? rawData[rowIndex] : null))}
            />
        );
    }
}
