import React, { PureComponent } from 'react';
import './default.scss';

export default class SelectRenderer extends PureComponent {
    render() {
        const {
            value,
            colDef: {
                cellEditorParams: { displayField }
            }
        } = this.props;

        const displayText = value && value[displayField] !== undefined ? value[displayField] : value;

        return (
            <span className="ag-cell-default" title={displayText}>
                {displayText}
            </span>
        );
    }
}
