import _ from 'lodash';
import classNames from 'classnames';
import React, { PureComponent } from 'react';
import SplitPane from 'react-split-pane';
import PerfectScrollbar from 'react-perfect-scrollbar';
import './container.scss';
import './splitpane.scss';
import 'react-perfect-scrollbar/dist/css/styles.css';

export default class Container extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            id: props.id || _.uniqueId(),
            isBeingDragged: false
        };
    }

    render() {
        const {
            children,
            reference,
            isColumn, isRow,
            alignSelf, alignItems, justifyContent, alignContent,
            cssClass,
            split, showSplitter, isCollapsed, primary } = this.props;
        const { id, isBeingDragged } = this.state;
        const MainHtmlTag = split && window.innerWidth > 640 ? SplitPane : 'div';
        return (
            <MainHtmlTag
                ref={reference}
                id={id}
                className={classNames('flex-container', cssClass, {
                    [`align-self-${alignSelf}`]: alignSelf,
                    [`align-items-${alignItems}`]: alignItems,
                    [`align-content-${alignContent}`]: alignContent,
                    [`justify-content-${justifyContent}`]: justifyContent,
                    'flex-container--col': isColumn,
                    'flex-container--row': isRow,
                    [`${primary === 'second' ? 'pane2' : 'pane1'}-collapsed`]: isCollapsed,
                    [`${primary === 'second' ? 'pane2' : 'pane1'}-expanded`]: !isCollapsed,
                    'split-container': split,
                    'hide-splitter': split && !showSplitter,
                    'width-transition': isBeingDragged
                })}
                {...getProps(this)}
            >
                {
                    React.Children.map(children, (child) => (split && window.innerWidth > 640 && child) ? <PerfectScrollbar>{child}</PerfectScrollbar> : child)
                }
            </MainHtmlTag>
        );
    }
}

const normalizeFlex = (flex) => {
    switch (flex.toString().split(' ').filter(x => x).length) {
        case 1:
            return flex + ' 1 auto';
        case 2:
            return flex + ' auto';
        default:
            return flex;
    }
};

const getProps = (scope) => {
    const { split, maxSize, minSize, size, style, resizerStyle, allowResize, flex, primary, isCollapsed, noPadding, height, backgroundColor, splitSectionsAmount = 2 } = scope.props;
    if (split && window.innerWidth > 640) {
        return {
            split,
            primary,
            style,
            resizerStyle,
            size,
            minSize,
            maxSize,
            allowResize,
            defaultSize: isCollapsed ? "0px" : `${primary === "second" ? 100 - (100 / splitSectionsAmount) : 100 / splitSectionsAmount}%`,
            onDragStarted: () => { scope.setState({ isBeingDragged: true }); },
            onDragFinished: () => { scope.setState({ isBeingDragged: false }); }
        };
    }
    return {
        style: {
            height: height || null,
            flex: normalizeFlex(flex),
            padding: noPadding ? '0' : null,
            backgroundColor: backgroundColor
        }
    };
};

Container.defaultProps = {
    alignContent: undefined,
    alignItems: undefined,
    alignSelf: undefined,
    backgroundColor: "transparent",
    cssClass: '',
    flex: '1 1 auto',
    height: null,
    isColumn: false,
    isRow: false,
    justifyContent: undefined,
    noPadding: null,
    split: undefined
};