import React, { PureComponent } from 'react';
import _ from 'lodash';
import classNames from 'classnames';

export default class DateClass extends PureComponent {
    constructor(props) {
        super(props);
        this.id = props.id || _.uniqueId();
    }

    render() {
        const [month, day] = getValidDateObj(this.props.children).toString().split(' ').slice(1);

        return (
            <div id={this.id} className={classNames("text-center flex-column", this.props.cssClass)} >
                <div className="clist__item-date-day">
                    {day}
                </div>
                <div className="clist__item-date-month">
                    {month.toUpperCase()}
                </div>
            </div>
        );
    }
}

DateClass.defaultProps = {
    cssClass: ''
};

function getValidDateObj(date) {
    let validDate = new Date(date);

    if (isNaN(Date.parse(validDate))) {
        validDate = new Date(0);
    }

    return validDate;
}