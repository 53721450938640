import _ from 'lodash';
import React, { PureComponent } from 'react';
import classNames from 'classnames';
import Icon from '../icon/icon';
import Label from '../field_label/field_label';
import './input_field.scss';

export const validationStates = Object.freeze({ 'BLANK': '', 'ERROR': 'error', 'WARNING': 'warning', 'INFO': 'info', 'SUCCESS': 'success' });

export default class InputField extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            id: props.id || _.uniqueId(),
            isFocused: false
        };
        this.handleFocusChange = this.handleFocusChange.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);
        this.handleOnFocus = this.handleOnFocus.bind(this);
    }

    handleFocusChange({ type }) {
        this.setState({ isFocused: type === 'focus' });
    }

    handleOnBlur(event) {
        this.handleFocusChange(event);
        this.props.onBlur(this.props);
    }

    handleOnFocus(event) {
        this.handleFocusChange(event);
        this.props.onFocus(this.props);
    }

    render() {
        const { state: { id, isFocused }, props: { dataType, cssClass, label, multiline, size, maxLength, description, validationStatus, hidden, inputGroup } } = this;
        const MainHtmlTag = multiline ? 'textarea' : 'input';
        const htmlInputField = <MainHtmlTag {...getPropsForMainTag(this) } />;

        return hidden ? (
            htmlInputField
        ) :
        (
            <div
                className={classNames('form__item', cssClass, {
                    [`form__item--${size}`]: size
                })}
            >
                { inputGroup ?
                    <div className={classNames("input-group", cssClass)}>
                        <div className={`input-group__${inputGroup.position === 'left' ? 'prepend' : 'append'}`}>
                            {inputGroup.icon ?
                                <span className="input-group__icon">
                                    <span className="form__icon"><Icon id={`${id}inputGroup`} icon={inputGroup.icon} title={inputGroup.title || ''}/></span>
                                </span> :
                                <span id={`${id}inputGroup`} className="input-group__text" title={inputGroup.title || ''}>{inputGroup.text || ''}</span>
                            }
                        </div>
                        {htmlInputField}
                    </div> :
                    htmlInputField
                }
                <Label {...{ id, isFocused, text: label, fieldType: dataType }} />
                {(!maxLength && !description) && validationStatus === validationStates.BLANK ? null : <DescriptionTagsAndCharactersCounter {...this} />}
            </ div>
        );
    }
}

InputField.defaultProps = {
    validationStatus: validationStates.BLANK,
    dataType: 'text',
    maxLength: null,
    size: null,
    readOnly: null,
    disabled: null,
    placeholder: null,
    description: null,
    cssClass: '',
    value: '',
    hidden: false,
    inputGroup: null
};

const DescriptionTagsAndCharactersCounter = ({ props: { value = '', maxLength, description } }) => (
    !maxLength &&
    < div className="input-counter__wrapper" >
        <span className="input__description">{description}</span>
    </div > || maxLength &&
    <div className="input-counter__wrapper">
        <span className="input__description">{description}</span>
        <span className='input__counter align-right'>
            {`${value.length}/${maxLength}`}
        </span>
    </div> || null
);

const getPropsForMainTag = ({
    props: { 'aria-describedby': ariaDescribedBy, cssClass, dataKey, dataType, hidden, inputGroup, label, mandatory, multiline,
        onBlur, onFocus, validationStatus, value, ...propsToHtmlTag },
    state: { id, formattedValue },
    handleFocusChange,
    handleOnFocus,
    handleOnBlur
}) => ({
        id,
        type: hidden && !multiline ? 'hidden' : dataType,
        className: classNames('input', {
            'input--textarea': multiline === true,
            [`is-${validationStatus}`]: validationStatus !== validationStates.BLANK
        }),
        value: formattedValue || value,
        onFocus: onFocus ? handleOnFocus : handleFocusChange,
        onBlur: onBlur ? handleOnBlur : handleFocusChange,
        'data-maxchars': multiline ? propsToHtmlTag.maxLength : null,
        'aria-required': mandatory === true || null,
        'required': mandatory ? 'required' : null,
        'aria-describedby': inputGroup ? `${id}inputGroup` : ariaDescribedBy,
        ...(hidden && multiline) ? {hidden: true} : null,
        ...propsToHtmlTag
    });