import React, { PureComponent } from 'react';
import classNames from 'classnames';
import './footer-container.scss';
import _ from 'lodash';

export default class FooterContainer extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            id: props.id || _.uniqueId()
        };
    }

    render() {
        const { props: { children, cssClass }, state: { id } } = this;
        const className = classNames('main-footer', cssClass);

        return (
            <footer className={className} id={id} >
                {children}
            </ footer>
        );
    }
}

FooterContainer.defaultProps = {
    cssClass: ''
};