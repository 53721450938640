import React, { PureComponent } from 'react';
import './default.scss';

export default class DefaultRenderer extends PureComponent {
    render() {
        const { value } = this.props;

        return (
            <span className="ag-cell-default" title={value || ''}>
                {value || ''}
            </span>
        );
    }
}
