import React, { PureComponent } from 'react';
import TextHighlighter from '../../../../util/text-highlighter';

export default class ListItemBodyTitle extends PureComponent {
    render() {
        const { children, isSubtitle, textToHighlight } = this.props;

        return children && (
            <div className={isSubtitle ? 'clist__item-subtitle' : 'clist__item-title'}>
                <TextHighlighter text={textToHighlight}>{children}</TextHighlighter>
            </div>
        );
    }
}

ListItemBodyTitle.defaultProps = {
    isSubtitle: false
};