import _ from 'lodash';
import React, { PureComponent } from 'react';
import ReactPaginate from 'react-paginate';
import './pagination.scss';

export default class Pagination extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            id: props.id || _.uniqueId()
        };
    }

    render() {
        const { id } = this.state;
        const { pageCount, pageRangeDisplayed, marginPagesDisplayed, currentPage, onPageChanged, rowsPerPage, numRows } = this.props;
        let reportRowsPerPageWrapper;
        let reportCurrentRowsWrapper;

        if (rowsPerPage > 0 && currentPage >= 0 && numRows > 0) {
            const firstRowDisplayed = rowsPerPage * currentPage + 1;
            const lastRowDisplayed = firstRowDisplayed + rowsPerPage - 1;
            const infoRowsPerPage = `Rows per page ${rowsPerPage}`;
            const infoCurrentRows = `Showing ${firstRowDisplayed}-${lastRowDisplayed > numRows ? numRows : lastRowDisplayed} of ${numRows}`;

            reportRowsPerPageWrapper = <div className="pagination-rows-per-page">{infoRowsPerPage}</div>;
            reportCurrentRowsWrapper = <div className="pagination-info-current-rows">{infoCurrentRows}</div>;
        }

        return (
            <div id={id} className="pagination-wrapper">
                {reportRowsPerPageWrapper}
                <div>
                    <ReactPaginate
                        pageCount={pageCount}
                        pageRangeDisplayed={pageRangeDisplayed}
                        marginPagesDisplayed={marginPagesDisplayed}
                        forcePage={currentPage}
                        onPageChange={onPageChanged}
                        disableInitialCallback={true}
                        previousLabel=""
                        nextLabel=""
                        containerClassName="pagination-container"
                        breakClassName="pagination-break"
                        pageClassName="pagination-page"
                        activeClassName="pagination-active"
                        previousClassName="pagination-previous"
                        nextClassName="pagination-next"
                    />
                </div>
                {reportCurrentRowsWrapper}
            </div>
        );
    }
}

Pagination.defaultProps = {
    currentPage: 1,
    marginPagesDisplayed: 1,
    pageRangeDisplayed: 4
};