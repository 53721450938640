import React, { PureComponent } from 'react';
import IconButton from '../../button/icon-button';
import Nullify from '../../nullify';
import MediaQuery from 'react-responsive';

export default class BottomToolbar extends PureComponent {
    constructor(props) {
        super(props);
        this.onButtonClick = ({ name }) => props.handler(name);
    }

    render() {
        const { children, isPDF, fitMode, nullifyIf, onButtonFocusChange } = this.props;
        return (
            <Nullify if={nullifyIf}>
                <div className="document-viewer__bottom-toolbar">
                    <MediaQuery minWidth={320}>
                        <div className={"document-viewer__rotation-toolbar"}>
                            <IconButton
                                name='Rotate'
                                icon="sync"
                                title="Rotate"
                                type="border"
                                color="grey"
                                onClick={this.onButtonClick}
                                onFocus={onButtonFocusChange}
                                onBlur={onButtonFocusChange}
                            />
                            <IconButton
                                name='Fit'
                                icon={(isPDF && fitMode === 'page') ? "compress" : "expand"}
                                title={!isPDF ? "Fit image" : (fitMode === 'page' ? "Fit to width" : "Fit to page")}
                                type="border"
                                color="grey"
                                onClick={this.onButtonClick}
                                onFocus={onButtonFocusChange}
                                onBlur={onButtonFocusChange}
                            />
                        </div>
                    </MediaQuery>
                    {children}
                    <div className={"document-viewer__zoom-toolbar"}>
                        <IconButton
                            name='Zoom_out'
                            icon="minus"
                            title="Zoom Out"
                            type="border"
                            color="grey"
                            onClick={this.onButtonClick}
                            onFocus={onButtonFocusChange}
                            onBlur={onButtonFocusChange}
                        />
                        <IconButton
                            name='Zoom_in'
                            icon="plus"
                            title="Zoom In"
                            type="border"
                            color="grey"
                            onClick={this.onButtonClick}
                            onFocus={onButtonFocusChange}
                            onBlur={onButtonFocusChange}
                        />
                    </div>
                </div>
            </Nullify>
        );
    }
}

BottomToolbar.Events = Object.freeze({ 'ROTATE': 'Rotate', 'FIT': 'Fit', 'ZOOM_IN': 'Zoom_in', 'ZOOM_OUT': 'Zoom_out' });

BottomToolbar.defaultProps = {
    handler() { },
    isPDF: false,
    nullifyIf: false
};