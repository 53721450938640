import _ from 'lodash';
import React, { PureComponent } from 'react';
import InputField from './input_field';
import * as CurrencyUtil from '../../util/currencyHelper';

export default class CurrencyField extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            id: props.id || _.uniqueId()
        };
        this.handleBlur = this.handleBlur.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleFocus(){
        const {locale, currencyCode, decimals, onFocus, value} = this.props;
        const {unformattedValue} = this.state;
        const valueToFormat = unformattedValue !== undefined ? unformattedValue : value;
        this.setState({
            formattedValue : CurrencyUtil.formatWithoutSymbol(valueToFormat, locale, currencyCode, decimals)
        });
        if(onFocus){
            onFocus(this.props);
        }
    }

    handleBlur({value}){
        const {currencyCode, locale, decimals, onBlur, inputGroup, hideCurrency} = this.props;
        this.setState({
            formattedValue: inputGroup || hideCurrency
                ? CurrencyUtil.formatWithoutSymbol(value, locale, currencyCode, decimals)
                : CurrencyUtil.formatToLocale(value, locale, currencyCode, decimals),
            unformattedValue: CurrencyUtil.unformat(value, locale, currencyCode)
        });
        if(onBlur){
            onBlur(this.props);
        }
    }

    handleChange({target: {value, selectionStart}}){
        const {locale, currencyCode} = this.props;
        if(!CurrencyUtil.isValidNumber(value, locale, currencyCode)){return '';}

        const cursorPosition = selectionStart;
        const unformattedValueLength = value.length;
        const formattedValue = CurrencyUtil.addGroupSeparator(value, locale, currencyCode);
        this.setState({
            formattedValue: formattedValue
        }, () => {
            document.activeElement.selectionEnd = cursorPosition + (formattedValue.length - unformattedValueLength);
        });
    }

    render() {
        const { state: { formattedValue }, props: { value, currencyCode, locale, decimals, onFocus, onBlur, onChange, inputGroup, hideCurrency, ...restOfProps } } = this;
        const initialValue = typeof value === 'number' && CurrencyUtil.isValidNumber(value, locale, currencyCode)
            ?  inputGroup || hideCurrency
                ? CurrencyUtil.formatWithoutSymbol(value, locale, currencyCode, decimals)
                : CurrencyUtil.formatToLocale(value, locale, currencyCode, decimals)
            : '';
        return (
            <React.Fragment>
                <InputField
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    value={formattedValue !== undefined ? formattedValue : initialValue}
                    inputGroup={inputGroup}
                    aria-describedby={this.state.id}
                    {...restOfProps}
                />
                {!inputGroup
                    ? <span
                        id={this.state.id}
                        style={{visibility:'hidden', position: 'absolute'}}
                        tabIndex={-1}
                    >
                        {CurrencyUtil.getLocalePart('currency', locale, currencyCode)}
                    </span>
                    : null}
            </React.Fragment>
        );
    }
}

CurrencyField.defaultProps = {
    currencyCode: 'EUR',
    locale: navigator.language
};