import _ from 'lodash';
import React, { PureComponent } from 'react';
import classNames from 'classnames';

export default class MenuItem extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            id: props.id || _.uniqueId()
        };

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {
        const { onClick, value } = this.props;

        if (onClick) {
            onClick(event, value);
        }
    }

    render() {
        const { children, href, text, onClick, autoFocus, autoClick, hasDivider, draggable, cssClass } = this.props;
        const { id } = this.state;
        return (
            <li
                className={classNames('clist__item', cssClass, {
                    'clist__item--divider': hasDivider
                })}
                id={id}
                role="none"
            >
                <a
                    {...(href && onClick === null) ? { href } : ""}
                    onClick={this.handleClick}
                    role="option"
                    tabIndex="-1"
                    className="clist__item-link"
                    ref={comp => comp && (autoClick && comp.click() || autoFocus && comp.focus())}
                    draggable={draggable}
                >
                    <div className="clist__item-inner-wrapper">
                        {children ?
                            children :
                            <div className="clist__item-title">
                                {text}
                            </div>
                        }
                    </div>
                </a>
            </li>
        );
    }
}

MenuItem.defaultProps = {
    text: 'New Item',
    href: '',
    shortcutText: '',
    onClick: null,
    autoFocus: false,
    autoClick: false,
    draggable: false,
    cssClass: ''
};