import React, { PureComponent } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import './side_drawer.scss';

export default class SideDrawer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            id: props.id || _.uniqueId(),
            sideDrawerWidth: 256
        };
        this.sideDrawerRef = React.createRef();
    }

    componentDidMount(){
        this.setState({
            sideDrawerWidth: this.sideDrawerRef.current.offsetWidth
        });
    }

    render() {
        const { props: { children, isCollapsed, cssClass }, state: { id, sideDrawerWidth } } = this;
        return(
            <div
                id={id}
                ref={this.sideDrawerRef}
                className={classNames('side-drawer', cssClass, {
                    'is-collapsed': isCollapsed
                })}
                style={{
                    marginLeft: isCollapsed ?
                    `-${sideDrawerWidth}px` :
                    "0px"
                }}
            >
                {children}
            </div>
        );
    }
}

SideDrawer.defaultProps = {
    cssClass: ''
};