import _ from 'lodash';
import React, { PureComponent } from 'react';
import DisplayField from '../display_field/display_field';
import Button from '../button/button';
import Mask from '../mask/mask';
import Container from '../container/container';
import Icon from '../icon/icon';
import IconButton from '../button/icon-button';
import FocusLock from 'react-focus-lock';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Draggable from 'react-draggable';
import Nullify from '../nullify';
import classNames from 'classnames';
import './modal.scss';

export default class Modal extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            id: props.id || _.uniqueId(),
            show: props.show
        };
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    handleKeyDown(event) {
        const { onClose } = this.props;
        switch (event.keyCode) {
            case 27:
                if (onClose) {
                    onClose();
                }
                break;
            default: break;
        }
    }

    render() {
        const { title, children, footer, showModal, onMaskClick, onClose, modalType, showIcon, cssClass, fullScreen, showHeader } = this.props;
        const { id } = this.state;
        const type = types[modalType.toUpperCase()];
        const ParentHtmlTag = fullScreen ? FocusLock : 'div';

        return (
            <Nullify if={!showModal} >
                <div id={id} className={classNames('modal__wrap is--opened', { 'modal-inside-container': !fullScreen }, cssClass)} >
                    <Mask onClick={onMaskClick} />
                    <Draggable cancel=".cancel__draggable" bounds="parent">
                        <div className="modal__body">
                            <ParentHtmlTag className={'modal'} {... fullScreen ? { lockProps : {
                                role: "dialog",
                                "aria-modal": "true",
                                "aria-labelledby": `ModalTitle${id}`,
                                "aria-describedby": `ModalMessage${id}`,
                                onKeyDown: this.handleKeyDown
                            } } : null}>
                                { showHeader ?
                                    <Container isRow cssClass={"modal__header"}>
                                        <Nullify if={!showIcon}>
                                            <Icon icon={type.icon} color={type.color} className={'modal__icon cancel__draggable'} />
                                        </Nullify>
                                        <DisplayField id={`ModalTitle${id}`} className="modal__title" value={title} headingLevel={1} noPadding />
                                        <IconButton cssClass={"fa modal__close cancel__draggable"} onClick={onClose} title="Close Modal" customIcon />
                                    </Container>
                                    : null
                                }
                                <PerfectScrollbar className='cancel__draggable'>
                                    <Container id={`ModalMessage${id}`} cssClass="modal__message">
                                        {children}
                                    </Container>
                                </PerfectScrollbar>
                                <Container isRow cssClass={`modal__footer modal__type-${modalType.toLowerCase()}`}>
                                    {footer}
                                </Container>
                            </ParentHtmlTag>
                        </div>
                    </Draggable>
                </div >
            </Nullify>
        );
    }
}

export const types = Object.freeze({
    'INFO': { icon: 'info-circle', color: "#0d78aa" },
    'SUCCESS': { icon: 'check-circle', color: "#388700" },
    'WARNING': { icon: 'exclamation-triangle', color: "#ed6f19" },
    'ERROR': { icon: 'exclamation-circle', color: "#c10606" },
    'SYSTEM': { icon: 'question-circle', color: "#4c5358" }
});

Modal.defaultProps = {
    modalType: 'SYSTEM',
    footer: <Button type='INFO' text="OK" onClick={() => { }}/>,
    showIcon: true,
    fullScreen: true,
    showHeader: true,
    cssClass: ''
};