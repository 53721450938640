import _ from 'lodash';
import React, { PureComponent } from 'react';
import classNames from 'classnames';
import './mask.scss';

export default class Mask extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            id: props.id || _.uniqueId()
        };
    }

    render() {
        const { onClick, cssClass, ...restOfProps } = this.props;
        const { id } = this.state;

        return (
            <div
                id={id}
                className={classNames('modal__overlay', cssClass, {
                    'clickable': onClick
                })}
                onClick={onClick}
                {...restOfProps}
            />
        );
    }
}

Mask.defaultProps = {
    cssClass: ''
};