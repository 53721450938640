import React, { PureComponent } from 'react';
import DisplayField from '../display_field/display_field';
import classNames from 'classnames';
import './emptyState.scss';

export default class EmptyState extends PureComponent {
    render() {
        const { headerValue, subheaderValue, cssClass, img } = this.props;
        const style = {
            'style': {
                backgroundImage: `url(${img})`
            }
        };
        const className = classNames('emptyState', cssClass);

        return (
            <section className={className}>
                {img === false ? '' : <div {...img ? style : ''} className='emptystate__img'></div>}
                <DisplayField value={headerValue} cssClass="emptystate__title" fontType="headline"/>
                <DisplayField value={subheaderValue} cssClass="emptystate__subtitle" fontType="subheading"/>
            </section>
        );
    }
}

EmptyState.defaultProps = {
    headerValue: 'No data',
    subheaderValue: '',
    cssClass: '',
    img: ''
};