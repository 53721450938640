import React, { PureComponent } from 'react';
import classNames from 'classnames';

export default class ListItemMicroContainer extends PureComponent {
    render () {
        const { cssClass, children } = this.props;

        return children && (
            <div className={classNames("clist__item-micro", cssClass)}>
                {children}
            </div>
        );
    }
}

ListItemMicroContainer.defaultProps = {
    cssClass: '',
    children: null
};