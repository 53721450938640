import _ from 'lodash';
import React, { PureComponent } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import './table.scss';
import './table-selection.scss';
import InputFieldEditor from '../input_field/input-field-editor';
import CheckboxEditor from '../checkbox/checkbox-editor';
import SelectEditor from '../select/select-editor';
import * as ObjectHelper from '../../util/objectHelper';
import Button from '../button/button';
import Pagination from '../pagination/pagination';
import CellRenderer from './cell-renderer';
import classNames from 'classnames';
import { inputSizes as sizes } from '../../util/objectHelper';
import Container from '../container/container';

const rowHeight = 36;
const editorHeight = 72;

export default class Table extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            id: props.id || _.uniqueId(),
            frameworkComponents: {
                inputField: InputFieldEditor,
                checkbox: CheckboxEditor,
                switch: CheckboxEditor,
                actions: CellRenderer.Actions,
                agSelectCellEditor: SelectEditor,
                selectRenderer: CellRenderer.SelectRenderer,
                default: CellRenderer.DefaultRenderer,
                hyperlink: CellRenderer.HyperlinkRenderer,
                ... (props.customFrameworkComponents || null)
            },
            amountOfRowsInCurrentPage: 0,
            addingRow: false,
            currentPage: 1,
            config: { ...getDefaultConfig(), ...(!props.config ? {} : typeof props.config === 'object' ? props.config : JSON.parse(props.config)) }
        };

        this.onCellKeyDown = this.onCellKeyDown.bind(this);
        this.handleOnCellValueChanged = this.handleOnCellValueChanged.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleWindowResize = this.handleWindowResize.bind(this);
        this.addRow = this.addRow.bind(this);
        this.calculateRowsInPage = this.calculateRowsInPage.bind(this);
        this.removeRow = this.removeRow.bind(this);
        this.tabToNextCell = this.tabToNextCell.bind(this);
        this.onRowDataChanged = this.onRowDataChanged.bind(this);
        this.onGridSizeChanged = this.onGridSizeChanged.bind(this);
        this.onRowEditingStarted = this.onRowEditingStarted.bind(this);
        this.onRowEditingStopped = this.onRowEditingStopped.bind(this);
        this.onPaginationChanged = this.onPaginationChanged.bind(this);
        this.onPageChanged = this.onPageChanged.bind(this);
        this.checkIfMustShowPagination = this.checkIfMustShowPagination.bind(this);
        this.onColumnResized = this.onColumnResized.bind(this);
        this.setAgGridRef = this.setAgGridRef.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    getRowHeight() {
        return rowHeight;
    }

    handleOnCellValueChanged({ colDef, newValue, oldValue, rowIndex, node }) {
        if (newValue !== oldValue) {
            const { name, onEdit } = this.props;
            let newValueObject;

            if (colDef.cellEditor === getCellEditor('select') && newValue) {
                const { displayField, valueField, valueId, descriptionField, descriptionId } = colDef.cellEditorParams;

                newValueObject = {
                    [valueId]: newValue[valueField],
                    [colDef.field]: newValue[displayField]
                };

                if (descriptionField) {
                    newValueObject[descriptionId] = newValue[descriptionField];
                }
            } else {
                newValueObject = {
                    [colDef.field]: newValue
                };
            }

            if (onEdit) {
                onEdit({ target: { type: 'table', name, rowIndex, updated: getNestedFieldsFromColumnTemplates(newValueObject) } });
            } else {
                node.setData(_.merge(node.data, newValueObject));
            }
        }
    }

    isEditing() {
        return this.agGrid.api.getEditingCells().length > 0;
    }

    tabToNextCell({ previousCellDef, nextCellDef, backwards }) {
        if (nextCellDef && ['checkbox', 'switch', 'actions'].some(x => x === nextCellDef.column.colDef.cellRenderer) && previousCellDef.rowIndex === nextCellDef.rowIndex && !this.isEditing()) {
            return null;
        }

        const previousColDef = previousCellDef.column.colDef;

        if (previousColDef.cellRenderer === 'actions') {
            const focusedElement = document.activeElement;
            const actions = previousColDef.cellRendererParams.actions;
            const lastAction = backwards ? actions[0] : actions[actions.length - 1];

            if (focusedElement.className.indexOf('actions--cell-renderer') !== -1 &&
                focusedElement.children[0].getAttribute('data-icon') !== lastAction.icon) {
                return null;
            }
        }

        return nextCellDef;
    }

    onCellKeyDown({ event, colDef, rowIndex, api, node }) {
        const { readOnly } = this.state.config;

        if (event.keyCode === 32) {
            if (colDef.field === '{selected}') {
                node.setSelected(!node.isSelected());
            } else if ((colDef.cellRenderer === 'checkbox' || colDef.cellRenderer === 'switch') && !readOnly && !this.isEditing()) {
                const inputElement = event.target.type === 'checkbox' ? event.target : event.target.getElementsByTagName('input')[0];
                if (inputElement) {
                    api.getRowNode(rowIndex).setDataValue(colDef, !inputElement.checked);
                }
            }
        }
    }

    onKeyDown(event) {
        if (event.keyCode === 32 && !this.isEditing()) {
            event.preventDefault();
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        window.addEventListener('resize', this.handleWindowResize);
        if (this.agGridWrapper) {
            this.agGridWrapper.addEventListener('keydown', this.onKeyDown);
        }
        if (this.isMountedAgGrid()) {
            this.agGrid.api.eventService.addEventListener('columnResized', this.onColumnResized);
        }
        const formattedColumns = this.createColumns(this.state.config);

        this.setState({
            formattedColumns,
            formattedRows: createRows(this.props.data, formattedColumns)
        });
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        window.removeEventListener('resize', this.handleWindowResize);
        if (this.agGridWrapper) {
            this.agGridWrapper.removeEventListener('keydown', this.onKeyDown);
        }
        if (this.isMountedAgGrid()) {
            this.agGrid.api.eventService.removeEventListener('columnResized', this.onColumnResized);
        }
    }

    isMountedAgGrid() {
        return Boolean(this.agGrid);
    }

    componentDidUpdate(prevProps) {
        const { columns, data, config: configProp } = this.props;

        const config = !configProp ? {} : typeof configProp === 'object' ? configProp : JSON.parse(configProp);
        const prevConfig = !prevProps.config ? {} : typeof prevProps.config === 'object' ? prevProps.config : JSON.parse(prevProps.config);

        if (!_.isEqual(data, prevProps.data) ||
            !_.isEqual(columns, prevProps.columns) ||
            !_.isEqual(config, prevConfig)) {
            const newConfig = { ...getDefaultConfig(), ...config };
            const formattedColumns = this.createColumns(newConfig);
            this.setState({
                formattedColumns,
                formattedRows: createRows(data, formattedColumns),
                config: newConfig
            }, () => this.agGrid.api.sizeColumnsToFit());
        }
    }

    handleClickOutside(event) {
        if (this.agGridWrapper && !this.agGridWrapper.contains(event.target)) {
            this.agGrid.api.stopEditing();
        }
    }

    handleWindowResize() {
        this.agGrid.api.stopEditing();
    }

    onGridSizeChanged(params) {
        if (this.isMountedAgGrid()) {
            params.api.sizeColumnsToFit();
        }
    }

    createActionColumn() {
        return {
            title: 'Actions',
            renderer: {
                type: 'actions',
                actions: []
            }
        };
    }

    getRemoveRowAction({ readOnly }) {
        return { icon: 'trash', name: 'Delete row', disabled: readOnly, clickHandler: this.removeRow, title: 'Delete row' };
    }

    defaultValue(col) {
        let defaultValue;

        switch (col.dataType) {
            case 'boolean':
                defaultValue = false;
                break;
            case 'number':
                defaultValue = 0;
                break;
            case 'date':
            case 'string':
            default:
                defaultValue = '';
        }

        let defaultValueObject = {};
        if (ObjectHelper.isSingleTemplate(col.fieldTemplate)) {
            defaultValueObject = ObjectHelper.getDeepObject(defaultValue, removeBracketsFromKey(col.fieldTemplate));
        } else {
            var fields = col.fieldTemplate.split('{');
            var fieldTemplate;
            for (var i = 1; i < fields.length; i++) {
                fieldTemplate = fields[i].split('}')[0];
                defaultValueObject = _.merge(defaultValueObject, ObjectHelper.getDeepObject(defaultValue, fieldTemplate));
            }
        }

        return defaultValueObject;
    }

    addRow() {
        const { name, onAddRow, columns } = this.props;

        if (columns) {
            let newTuple = {};

            for (const col of columns) {
                if (col.fieldTemplate) {
                    const defaultValue = this.defaultValue(col);
                    newTuple = _.merge(newTuple, defaultValue);
                }
            }

            this.setState({
                addingRow: true
            });

            if (onAddRow) {
                onAddRow({ target: { name, newTuple } });
            }
        }
    }

    removeRow(event) {
        const { name, onRemoveRow } = this.props;
        const { readOnly } = this.state.config;

        if (!readOnly) {
            onRemoveRow({ target: { name, index: event.rowIndex } });
        }
    }

    onRowDataChanged() {
        if (this.isMountedAgGrid()) {
            const { addingRow } = this.state;
            const { api, columnApi } = this.agGrid;

            if (addingRow) {
                const { data } = this.props;
                const index = this.findFirstFocuseableColumn();

                if (index !== -1) {
                    if (this.checkIfMustShowPagination()) {
                        api.paginationGoToLastPage();
                    }
                    api.startEditingCell({
                        rowIndex: data.length - 1,
                        colKey: columnApi.getAllDisplayedColumns()[index].colId
                    });
                    const firstFocusableCellEditor = api.getEditingCells()[this.findFirstEditableEditor()];
                    api.setFocusedCell(firstFocusableCellEditor.rowIndex, firstFocusableCellEditor.column.colId, null);
                }
            }
        }
    }

    findFirstFocuseableColumn() {
        return this.agGrid.columnApi.getAllDisplayedColumns().findIndex((col) => {
            return col.colDef.editable !== false && col.colDef.field && col.visible;
        });
    }

    findFirstEditableEditor() {
        const firstFocusableColumn = this.agGrid.columnApi.getAllDisplayedColumns()[this.findFirstFocuseableColumn()];

        return this.agGrid.api.getEditingCells().findIndex((editor) => {
            return editor.column.colId === firstFocusableColumn.colId;
        });
    }

    formatColumn({ fieldTemplate, title, editor, renderer, editable, width, sortable, multiselect }, { readOnly, resizableColumns }) {
        const { editorData: editorDataProp, data } = this.props;
        const cellEditorParams = ObjectHelper.isObject(editor) ? editor : {};
        const cellEditor = cellEditorParams.type || editor || 'inputField';

        cellEditorParams['editorData'] = editorDataProp;
        cellEditorParams['rowHeight'] = this.getRowHeight();

        const cellRenderer = getCellRenderer(cellEditor, renderer);

        return {
            'field': fieldTemplate || 'empty',
            'headerName': title,
            'headerTooltip': title,
            'editable': (editable !== undefined) ? editable && !readOnly : !readOnly,
            'cellEditor': cellRenderer === 'actions' ? cellRenderer : getCellEditor(cellEditor),
            'cellEditorParams': (cellRenderer === 'actions') ? renderer : _.assignIn(cellEditorParams, { readOnly }),
            'cellRenderer': cellRenderer || 'default',
            'cellRendererParams': { ...(renderer || null), rawData: data },
            'minWidth': sizes.hasOwnProperty(width) ? sizes[`${width}`].minWidth : (cellRenderer === 'actions') ? sizes.SMALL.minWidth : sizes.MEDIUM.minWidth,
            'resizable': resizableColumns,
            'sortable': sortable,
            ...(multiselect ? {
                'minWidth': 52,
                'maxWidth': 52,
                'width': 52,
                'cellClass': 'selected--row',
                'headerCheckboxSelection': true,
                'checkboxSelection': true
            } : null)
        };
    }

    onColumnResized({ api, source }) {
        if (this.isMountedAgGrid()) {
            const tableWidth = this.agGrid.eGridDiv.offsetWidth;
            const bodyColumnWidth = api.columnController.bodyWidth;
            if (source === 'uiColumnDragged') {
                this.agGrid.api.stopEditing();
                if (bodyColumnWidth < tableWidth) {
                    api.sizeColumnsToFit();
                }
            }
        }
    }

    createColumns(config) {
        const { multiselect } = config;
        const { columns: columnsProp } = this.props;
        const columns = !columnsProp ? [] : Array.isArray(columnsProp) ? columnsProp : JSON.parse(columnsProp);
        let columnsFormatted = [];

        if (multiselect) {
            const multiselectColumn = {
                fieldTemplate: '{selected}',
                title: '',
                multiselect: true,
                editable: false
            };
            const formattedMultiselectColumn = this.formatColumn(multiselectColumn, config);

            columnsFormatted.push(formattedMultiselectColumn);
        }

        columnsFormatted = columnsFormatted.concat(_.map(columns, (col) => this.formatColumn(col, config)));

        if (config.allowRemoveRow) {
            const actionsColumnIndex = columnsFormatted.findIndex((column) => column.cellRenderer === 'actions');
            let actionColumn;

            if (actionsColumnIndex !== -1) {
                actionColumn = columnsFormatted[actionsColumnIndex];
                actionColumn.cellRendererParams = _.cloneDeep(actionColumn.cellRendererParams);
                actionColumn.cellEditorParams = _.cloneDeep(actionColumn.cellEditorParams);
                actionColumn.cellEditorParams.actions.push(this.getRemoveRowAction(config));
            } else {
                actionColumn = this.formatColumn(this.createActionColumn(), config);
                columnsFormatted.push(actionColumn);
            }

            actionColumn.cellRendererParams.actions.push(this.getRemoveRowAction(config));
        }

        return columnsFormatted;
    }

    checkIfMustHavePagination() {
        const { pagination } = this.state.config;

        return pagination && pagination > 0;
    }

    checkIfMustShowPagination() {
        const { data } = this.props;
        const { pagination } = this.state.config;
        const mustHavePagination = this.checkIfMustHavePagination();
        const numberOfRowsPerPageGreaterThanCurrentRows = data ? data.length > pagination : false;

        return mustHavePagination && numberOfRowsPerPageGreaterThanCurrentRows;
    }

    calculateRowHeight(numberOfRows) {
        return ((numberOfRows + 1) * rowHeight) + (editorHeight - rowHeight);
    }

    onRowEditingStarted(params) {
        const { readOnly } = this.state.config;

        if (!readOnly) {
            params.node.setRowHeight(editorHeight);
            params.api.onRowHeightChanged();
            if (this.state.addingRow && params.api.getCellEditorInstances().length > 0) {
                params.api.getCellEditorInstances()[this.findFirstEditableEditor()].focusIn();
            }
        }
    }

    onRowEditingStopped(params) {
        const { readOnly } = this.state.config;

        if (!readOnly) {
            params.node.setRowHeight(rowHeight);
            params.api.onRowHeightChanged();
            if (this.state.addingRow) {
                this.setState({
                    addingRow: false
                });
            }
        }
    }

    onPageChanged({ selected }) {
        this.agGrid.api.paginationGoToPage(selected);
    }

    onPaginationChanged({ api, newPage }) {
        if (this.isMountedAgGrid()) {
            this.calculateRowsInPage();
            if (newPage) {
                api.resetRowHeights();
                api.onRowHeightChanged();
            }
            this.setState({ currentPage: api.paginationGetCurrentPage() });
        }
    }

    calculateRowsInPage() {
        const amountOfRowsInCurrentPage = this.isMountedAgGrid() && this.state.config.pagination
            ? this.agGrid.api.paginationProxy.bottomRowIndex - this.agGrid.api.paginationProxy.topRowIndex + 1
            : this.props.data.length;

        this.setState({
            amountOfRowsInCurrentPage: amountOfRowsInCurrentPage
        });
    }

    setAgGridRef(referenceToAgGrid) {
        this.agGrid = referenceToAgGrid;
    }

    onSelectionChanged({ api }) {
        if (this.props.onSelectionChanged) {
            this.props.onSelectionChanged(api.getSelectedNodes().map(a => a.childIndex));
        }
    }

    render() {
        const {
            props: { data, cssClass, topContainer },
            state: {
                id, frameworkComponents, amountOfRowsInCurrentPage, formattedRows, formattedColumns, currentPage,
                config: { allowAddRow, height, readOnly, pagination, addButtonType, multiselect }
            }
        } = this;

        const mustShowPagination = this.checkIfMustShowPagination();
        const mustHavePagination = this.checkIfMustHavePagination();
        const tableHeight = height ? height : this.calculateRowHeight(amountOfRowsInCurrentPage) + (mustShowPagination && this.agGrid ? 42 : 0);
        let button = null;
        let paginationComponent = null;

        if (mustShowPagination && this.agGrid) {
            const pageCount = this.agGrid.api.paginationGetTotalPages();

            paginationComponent =
                <div>
                    <Pagination
                        currentPage={currentPage}
                        pageCount={pageCount}
                        onPageChanged={this.onPageChanged}
                        rowsPerPage={pagination}
                        numRows={data.length}
                    />
                </div>;
        }

        if (allowAddRow) {
            button = <Button type={addButtonType} name="Add row" size='SMALL' text='Add row' onClick={this.addRow} disabled={readOnly} />;
        }

        return (
            <div className={classNames("ag-grid__wrapper", cssClass)} id={id}>
                { (button || topContainer) &&
                    <Container>
                        { button &&
                            <Container>
                                {button}
                            </Container>
                        } { topContainer &&
                            <Container flex={6}>
                                {topContainer}
                            </Container>
                        }
                    </Container>
                }
                <div ref={(ref) => { this.agGridWrapper = ref; }} style={{ height: tableHeight, display: (height ? undefined : 'grid') }}>
                    <AgGridReact
                        ref={this.setAgGridRef}
                        animateRows={true}
                        onGridSizeChanged={this.onGridSizeChanged}
                        onRowDataChanged={this.onRowDataChanged}
                        onCellValueChanged={this.handleOnCellValueChanged}
                        onRowEditingStarted={this.onRowEditingStarted}
                        onRowEditingStopped={this.onRowEditingStopped}
                        onPaginationChanged={this.onPaginationChanged}
                        onSelectionChanged={this.onSelectionChanged}
                        tabToNextCell={this.tabToNextCell}
                        columnDefs={formattedColumns}
                        frameworkComponents={frameworkComponents}
                        domLayout={height ? 'normal' : 'autoHeight'}
                        editType="fullRow"
                        getRowHeight={this.getRowHeight}
                        headerHeight={rowHeight}
                        rowData={formattedRows}
                        suppressFieldDotNotation={true}
                        enableBrowserTooltips={true}
                        pagination={mustHavePagination}
                        paginationPageSize={pagination}
                        onCellKeyDown={this.onCellKeyDown}
                        suppressPaginationPanel={true}
                        suppressLoadingOverlay={true}
                        suppressClickEdit={readOnly}
                        suppressDragLeaveHidesColumns={true}
                        rowSelection={multiselect ? 'multiple' : 'single'}
                        suppressRowClickSelection={true}
                    />
                    {height ? null : paginationComponent}
                </div>
                {height ? paginationComponent : null}
            </div>
        );
    }
}

function getDefaultConfig() {
    return {
        allowAddRow: false,
        allowRemoveRow: false,
        readOnly: false,
        resizableColumns: true,
        pagination: 10
    };
}

function createRows(data, columns) {
    if (data && Array.isArray(data)) {
        return _.map(data, (row) => {
            const rowFormatted = {};

            _.forEach(columns, ({ field, cellEditor, cellEditorParams }) => {
                if (cellEditor === getCellEditor('select')) {
                    rowFormatted[cellEditorParams.valueId] = ObjectHelper.getTemplateValues(cellEditorParams.valueId, row);
                    if (cellEditorParams.descriptionId) {
                        rowFormatted[cellEditorParams.descriptionId] = ObjectHelper.getTemplateValues(cellEditorParams.descriptionId, row);
                    }
                }

                rowFormatted[field] = ObjectHelper.getTemplateValues(field, row);
            });

            return rowFormatted;
        });
    }
}

function getCellRenderer(cellEditor, renderer) {
    switch (cellEditor) {
        case 'select':
            return 'selectRenderer';
        case 'checkbox':
        case 'switch':
            return cellEditor;
        default:
            return renderer && renderer.type;
    }
}

function getCellEditor(cellEditor) {
    switch (cellEditor) {
        case 'select':
            return 'agSelectCellEditor';
        default:
            return cellEditor;
    }
}

function getNestedFieldsFromColumnTemplates(obj) {
    return _.chain(obj).map(getNestedFieldsFromColumnTemplate).reduce(_.merge, {}).value();
}

function getNestedFieldsFromColumnTemplate(value, templateKey) {
    return ObjectHelper.getDeepObject(value, removeBracketsFromKey(templateKey));
}

function removeBracketsFromKey(keyWithBrackets) {
    return keyWithBrackets.substr(1, keyWithBrackets.length - 2);
}
