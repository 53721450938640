import React from 'react';
import ListItemSkeleton from './item';

const defaultLength = 6;

export default class ListSkeleton extends React.PureComponent {
    render() {
        const { length, hasDivider } = this.props;
        return Array.from(Array(isNaN(length) || length < 0 ? defaultLength : length), (_, i) => <ListItemSkeleton key={i} hasDivider={hasDivider} />);
    }
}

ListSkeleton.defaultProps = {
    length: defaultLength
};