import {isObject} from './objectHelper';

export function getActiveSourceData(props, activeDocIndex, defaultMimeType) {
    return Array.isArray(props.source) ? getSourceFromArray(activeDocIndex, props.source, defaultMimeType) : props;
}

export const isBase64 = (source) => {
    if (source instanceof Boolean || typeof source === 'boolean') {
        return false;
    }
    var regex = /^(data:\w+\/[a-zA-Z+\-.]+;base64,)?(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/gi;
    return regex.test(source);
};

export const getCorrectMimeType = mimeType => isSVG(mimeType) ? correctSVGMimeType(mimeType) : mimeType;

function getSourceFromArray(activeDocIndex, sourceList, defaultMimeType) {
    let source = activeDocIndex < sourceList.length ? sourceList[activeDocIndex] : sourceList[0];
    if (!isObject(source)) {
        source = {
            source: source,
            mimeType: defaultMimeType
        };
    }
    return source;
}

const isSVG = mimeType => mimeType.indexOf('svg') > -1;

const correctSVGMimeType = mimeType => mimeType + (mimeType.indexOf('xml') < 0 ? '+xml' : '');