import _ from 'lodash';
import React, { PureComponent } from 'react';
import Checkbox, { Switch } from './checkbox';
import './checkbox-editor.scss';

export default class CheckboxEditor extends PureComponent {
    constructor(props) {
        super(props);

        const checked = props.value || false;

        this.state = {
            id: props.id || _.uniqueId(),
            checked: checked,
            isSwitch: props.colDef && props.colDef.cellEditor === 'switch'
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange({ target: { checked } }) {
        const { colDef } = this.props;

        if(colDef && (! colDef.cellEditorParams || ! colDef.cellEditorParams.readOnly)) {
            const { rowIndex, api, node, colDef: { field } } = this.props;
            this.setState({ checked });

            var isRowEditing = api.getEditingCells().some(cell => cell.rowIndex === rowIndex);
            if (!isRowEditing) {
                node.setDataValue(field, checked);
            }
        }
    }

    getValue() {
        return this.state.checked;
    }

    refresh(props) {
        if (props.value !== this.state.checked) {
            this.setState({ checked: props.value });
        }

        return true;
    }

    afterGuiAttached() {
        const { eGridCell, cellStartedEdit } = this.props;
        const { isSwitch } = this.state;

        this.checkboxElement = eGridCell.getElementsByClassName(isSwitch ? 'switch__check' : 'input--checkbox')[0];

        if (cellStartedEdit) {
            this.checkboxElement.focus();
        }
    }

    focusIn() {
        this.checkboxElement.focus();
    }

    render() {
        const { id, checked, isSwitch } = this.state;

        return (
            <div className="checkbox-editor">
                {
                    isSwitch ?
                        <Switch
                            id={id}
                            checked={checked}
                            inline={true}
                            size="small"
                            onChange={this.handleChange}
                        /> :
                        <Checkbox
                            id={id}
                            checked={checked}
                            inline={true}
                            size="small"
                            onChange={this.handleChange}
                        />
                }
            </div>
        );
    }
}
