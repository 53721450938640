import _ from 'lodash';
import React, { PureComponent } from 'react';
import MenuList from './menu_list';
import Button from '../button/button';
import OutsideAlerter from '../../util/outsideAlerter';
import classNames from 'classnames';
import './menu.scss';

export default class Menu extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            id: props.id || _.uniqueId(),
            expanded: false,
            buttonAutoFocus: false,
            surplusWidth: 0,
            surplusHeight: 0
        };

        this.handleToggleMenu = this.handleToggleMenu.bind(this);
        this.setButtonRef = this.setButtonRef.bind(this);
    }

    setButtonRef(buttonRef) {
        this.buttonRef = buttonRef;
    }

    handleToggleMenu(event = {}) {
        if (this.state.expanded) {
            this.setState(() => ({ expanded: false, buttonAutoFocus: true, surplusHeight: 0, surplusWidth: 0 }));
        } else if (this.shouldExpandMenu(event)) {
            this.setState(() => ({ expanded: true, buttonAutoFocus: false }));
        }
    }

    shouldExpandMenu(event) {
        const { type, keyCode } = event;
        return type === 'click' || (type === 'keydown' && (keyCode === 32 || keyCode === 13));
    }

    checkIfMenuListOverflowsWindow() {
        if (this.state.expanded) {
            const menuListRect = document.getElementById(`${this.state.id}MenuList`).getBoundingClientRect();
            const footer = document.querySelector("footer");
            let buttonInsideFooter = null;
            let footerHeight = 0;

            if (footer) {
                footerHeight = footer.getBoundingClientRect().height;
                buttonInsideFooter = footer.querySelector(`[id='${this.buttonRef.props.id}']`);
            }

            let parentWidth = 0;
            let parentHeight = 0;

            if(this.props.parentRef){
                const parentElement = this.props.parentRef.current;
                parentWidth = parentElement.clientWidth;
                parentHeight = parentElement.clientHeight;
            }
            else{
                parentWidth = window.innerWidth || document.documentElement.clientWidth
                || document.body.clientWidth;

                parentHeight = window.innerHeight || document.documentElement.clientHeight
                || document.body.clientHeight;
            }

            const heightDistanceDiff = menuListRect.y - (this.props.parentRef ? parentHeight : 0);
            const totalMenuHeight = menuListRect.height + this.state.buttonRect.height;
            const totalMenuWidth = menuListRect.width - this.state.buttonRect.width;

            const positionYPlusHeight = menuListRect.height + menuListRect.y;
            const positionXPlusWidth = menuListRect.width + menuListRect.x;
            const mustOpenMenuListAboveBottom = (parentHeight < positionYPlusHeight + footerHeight &&
                heightDistanceDiff > totalMenuHeight) ||
                (buttonInsideFooter != null);

            const mustOpenMenusListLeftSideBottom = parentWidth < positionXPlusWidth &&
                menuListRect.x > totalMenuWidth;

            if (mustOpenMenusListLeftSideBottom)
                this.setState({ surplusWidth: totalMenuWidth });

            if (mustOpenMenuListAboveBottom)
                this.setState({ surplusHeight: totalMenuHeight });
        }
    }

    measureButton() {
        this.setState({
            buttonRect: document.getElementById(this.buttonRef.props.id).getBoundingClientRect()
        });
    }

    componentDidMount() {
        this.measureButton();
        this.checkIfMenuListOverflowsWindow();
    }

    componentDidUpdate({ text }) {
        if (this.props.text !== text)
            this.measureButton();
        this.checkIfMenuListOverflowsWindow();
    }

    render() {
        const { button, text, data, children, cssClass } = this.props;
        const { id, expanded, buttonAutoFocus, surplusHeight, surplusWidth } = this.state;

        return (
            <OutsideAlerter handler={expanded ? this.handleToggleMenu : null}>
                <div className={classNames("external-menu-wrapper", cssClass)}>
                    {button ?
                        React.cloneElement(button, {
                            id: button.props.id || `${id}Button`,
                            ref: this.setButtonRef,
                            onClick: this.handleToggleMenu,
                            autoFocus: buttonAutoFocus
                        }) :
                        <Button
                            id={`${id}Button`}
                            ref={this.setButtonRef}
                            size="small"
                            text={text}
                            onClick={this.handleToggleMenu}
                            autoFocus={buttonAutoFocus}
                        />
                    }

                    <MenuList
                        id={`${id}MenuList`}
                        expanded={expanded}
                        data={data}
                        forceCollapse={this.handleToggleMenu}
                        surplusHeight={surplusHeight}
                        surplusWidth={surplusWidth}
                    >
                        {children}
                    </MenuList>
                </div>
            </OutsideAlerter>
        );
    }
}

Menu.defaultProps = {
    cssClass: ''
};


